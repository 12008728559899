@charset "utf-8";
/* @import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"); */

/* variables */
:root {
  --primarycolor: #821a1a;
  /* --primarycolor: #e6bc5b; */
  --secondarycolor: #d00035;
  --white: #ffffff;
  --lightwhite: rgba(255, 255, 255, 0.6);
  --black: #000000;
  --bgcolor: #f1f0eb;
  --formbgcolor: #f3f3f3;
}

/* CSS Document */
* {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}
body {
  font-display: swap;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
}
.form-control {
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
  padding: 0.75rem 0.75rem !important;
}
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}
.clear-cart-btn {
  border-radius: 0 !important;
}
img {
  max-width: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primarycolor);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(189, 155, 76);
}

h2 {
  font-family: "Playfair", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 40px !important;
  line-height: 51px !important;
}
h2 span {
  /* font-style: italic; */
  font-family: "Playfair", sans-serif;
  font-weight: 500;
}

.top_app_sec {
  /* background: var(--primarycolor); */
  background: var(--black);
}
.top_app_sec p {
  color: var(--white);
  font-size: 12px;
  font-family: "Playfair", sans-serif;
  margin: 0;
}
.top_app_sec .curveStyle {
  color: var(--white);
  font-family: "Playfair", sans-serif;
  font-size: 14px;
  font-style: italic;
  margin: 0;
  margin-left: 5px;
}
.top_app_sec .curveStyle .OffPer {
  font-weight: 600;
}
.top_app_sec .top_app_icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.top_app_sec .top_app_icons img {
  width: 110px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 6px;
}
.top_app_sec .top_app_icons a {
  text-decoration: none;
  color: var(--black);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.top_app_sec .top_app_icons a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
header {
  position: absolute;
  min-height: 80px;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
}

.offcanvas-header {
  box-shadow: 4px -86px 103px #000000e6;
}
.offfer-top-sec {
  background-color: var(--primarycolor);
  color: var(--white);
  width: 100%;
  display: grid;
  align-content: center;
  position: relative;
}
.top-close {
  position: absolute;
  right: 11px;
  top: 12px;
  color: var(--white);
}
.top-head {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: #212529;
}
.line-bg {
  position: relative;
  top: 17px;
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 3%));
}
.line-bg img {
  width: 100%;
}
.comon-cart-ps {
  padding: 15px 19px 14px;
  margin: 0;
  border-bottom: solid 1px var(--bgcolor);
  width: 100%;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.cat-count {
  position: absolute;
  bottom: 2px;
  display: grid;
  width: 20px;
  height: 20px;
  background: var(--primarycolor);
  color: var(--white);
  font-size: 12px;
  align-content: center;
  border-radius: 50%;
  overflow: hidden;
  left: 12px;
  font-weight: 600;
  text-align: center;
  pointer-events: none;
}
.cart-bn {
  background: var(--primarycolor);
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
  display: grid;
  align-content: center;
  color: var(--white);
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 150%;
  transition: all 0.5s;
  font-size: 22px;
}
.cart-dropdown-ne {
  width: 310px;
  right: 0;
  left: inherit !important;
  border: none !important;
  visibility: hidden;
  transition: all 0.5s;
  top: 120% !important;
  display: block !important;
  border-radius: 0 !important;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.mapWrapper {
  position: relative;
}
.cart-dropdown-ne.show {
  visibility: visible;
  top: 100%;
  top: 100%;
}
.sub-total-products {
  padding: 10px 15px;
}
.sub-total-products h4 {
  color: var(--primarycolor);
  font-weight: 600;
  font-size: 18px;
}

.titel-crt-products {
  color: #212529;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}
h6 .titel-crt-quantity {
  color: #212529;
  font-size: 12px;
}
.cart-ps-details {
  line-height: normal;
}

.cart-ps-details h6 {
  color: var(--primarycolor);
  font-weight: 600;
  margin-top: 5px;
}
.close-crt {
  color: #212529;
}
.products-sm-pic figure {
  height: 60px;
  width: 60px;
  overflow: hidden;
  margin: 0;
}
.products-sm-pic figure img {
  /* object-fit: cover; */
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.navbar-brand img {
  position: relative;
  /* z-index: 2; */
  width: 80px;
}

.navbar {
  padding-bottom: 0;

  border-radius: 8px;
  padding: 15px;
}
.mn-head .nav-link {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--white) !important;
  margin: 0 10px;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
  border-radius: 0;
  transition: all 0.5s;
}
.navbar-toggler {
  padding: 0 !important;
  border: 0 !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

.dropdown-menu1 {
  /* width: 90%; */
  width: auto;
  top: 120% !important;
  border: none !important;
  /* left: 9%; */
  left: 50%;
  /* background: rgba(0, 0, 0, 88%) !important; */
  background: var(--bgcolor) !important;
  padding: 15px 0 !important;
  display: block !important;
  transition: all 0.5s !important;
  visibility: hidden !important;
  opacity: 0 !important;
}
.dropdown-menu1 .comon-menu-div h5 {
  color: var(--white);
  font-weight: 600;
  font-size: 18px;
}
.dropdown-menu1 .comon-menu-div hr {
  background-color: #bbb !important;
  margin: 0 0 5px 0;
}
.dropdown-menu1 .comon-menu-div ul li {
  margin-bottom: 0.5rem;
}
.dropdown-menu1 .comon-menu-div ul li:last-child {
  margin-bottom: 0;
}
.dropdown-menu1 .comon-menu-div ul li a {
  text-decoration: none !important;
  color: var(--black) !important;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.5s;
  text-transform: capitalize;
  border-bottom: none !important;
}
.dropdown-menu1 .comon-menu-div ul li a:hover {
  color: var(--primarycolor) !important;
}
.com-link {
  color: var(--white);
  font-size: 18px;
  text-transform: capitalize;
}
.com-link:nth-child(2) {
  font-size: 28px;
}
/* .com-link:last-child {
  background: var(--primarycolor);
  color: var(--white);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  overflow: hidden;
  display: grid;
  align-content: center;
  justify-content: center;
} */
.mn-head .navbar-nav .active {
  color: var(--primarycolor) !important;
}
.fixed-menu .navbar-nav .active {
  color: var(--primarycolor) !important;
  background: none;
}
.mn-head .nav-link:hover {
  color: var(--primarycolor) !important;
}
.navbar-nav .dropdown-item {
  font-size: 14px;
  font-weight: 600;
}
.navbar-nav .dropdown-item:hover {
  color: var(--white) !important;
  background: #043a64;
}
.navbar-collapse {
  transition: 0.3s;
}
.top-head .nav-link {
  color: #212529 !important;
  font-size: 16px;
  transition: all 0.5s;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 600;
}
.fixed-menu .nav-link {
  /* color: var(--black) !important; */
}
.sc-logo {
  display: none;
}
.fixed-menu .ds-logo {
  display: none !important;
}
.fixed-menu .sc-logo {
  display: block;
}

.fixed-menu .com-link {
  color: var(--black) !important;
}
/* .fixed-menu .com-link:last-child {
  color: var(--white) !important;
} */
.right-menu li a {
  margin: 0 20px;
  color: var(--white) !important;
}
.ActionContainer {
  position: relative;
  float: right;
}
.DotsIcon {
  cursor: pointer;
}
.ActionContainer .ActionList {
  list-style: none;
  position: absolute;
  top: 20px;
  right: 0;
  background-color: var(--white);
  border-radius: 2px;
  border: 0.3px solid rgba(129, 129, 129, 0.55);
  min-width: 100px;
  overflow-x: hidden;
}
.ActionContainer .ActionList .actionList-item {
  padding: 10px 8px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 5px;
}
.ActionContainer .ActionList .actionList-item:hover {
  background-color: var(--primarycolor);
  color: var(--white);
}
.com-link svg {
  color: var(--white);
  font-size: 20px;
}
.TrashAddress,
.EditAddress {
  cursor: pointer;
}
.TrashAddress svg {
  color: rgb(193, 3, 3);
}
.EditAddress svg {
  color: rgb(61, 61, 216);
}
.cart-new-icon svg {
  font-size: 24px !important;
}

.cart-new-icon:after {
  display: none !important;
}
.cart-new-icon:focus {
  box-shadow: none;
}
.cart-new-icon {
  font-size: 25px;
  padding-top: 0;
  padding-bottom: 0;
}

/* ***********Banner css************* */

.banner-part {
  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 640px;
  display: flex;
  align-items: center; */

  position: relative;
  width: 100%;
  height: 640px; /* Set the height you desire for the banner */
  overflow: hidden;
}
.banner-part .banner_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the whole banner area */
  background-color: lightgray;
}

.banner-part .banner-content {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  right: 10%;
  transform: translate(0, -50%);
  width: 38%;
  /* background: rgba(0, 0, 0, 0.5); */
  /* border-radius: 5px; */
  /* padding: 1.5rem; */
}
.banner-contentUpdated {
  width: 50%;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 25px;
}

.banner-part .banner-content h2 {
  text-transform: capitalize !important;
  /* font-size: 17px !important; */
  letter-spacing: 1px;
  font-size: 45px !important;

  font-weight: 400;
  line-height: normal;
  /* margin: 0; */
  /* font-family: "Lobster Two", cursive; */
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Playfair", sans-serif;
  font-display: swap;

  color: var(--white);
}

.banner-part .banner-content h2 span {
  color: var(--white);
  font-size: 45px !important;
  font-style: normal;
  font-family: "Playfair", sans-serif;
  letter-spacing: 1px;
  font-display: swap;
}

.banner-part .banner-content h4 {
  font-size: 32px;
  /* font-size: 40px; */
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  color: var(--white);
  text-transform: capitalize;
  line-height: 1.2;
  font-display: swap;

  /* font-style: italic; */
}
.banner-part .banner-content p {
  color: var(--white);
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  position: relative;
  z-index: 2;
  font-size: 14px;
}

.mbl_banner_form_wrape {
  display: none;
}

.banner-form {
  /* background: var(--white); */
  /* border-radius: 6px; */
  /* padding: 1rem; */
  /* border-radius: 100px; */
  /* padding: 0.5rem; */
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; */
}

.banner-form span {
  font-weight: 600;
  font-size: 16px;
}
.banner-form .form-control {
  /* border-radius: 100px; */
  border-radius: 0;
  border: 0;
  background: var(--formbgcolor);
  border: 1px solid #d4dadd;
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  padding: 0.5rem 1rem;
  border-right: 0;
  /* animation: fadeIn 0.7s; */
}

.banner-form .form-control:focus {
  border: 1px solid var(--primarycolor);
  background: var(--white);
  box-shadow: none;
  border-right: 0;
}

.banner-form .form-check {
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}
.banner-part
  .banner-content
  .banner-form
  .form-check
  .form-check-input:checked {
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
}
.banner-form .form-check .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 188, 91, 0.25);
}
.banner-form .input-group-text {
  border: 1px solid var(--primarycolor);
  /* border-radius: 100px; */
  border-radius: 0;
  color: var(--white);
  background: var(--primarycolor);
  cursor: pointer;
}
.banner-form .select_as_btn {
  padding: 1rem 2.5rem !important;
  background: var(--primarycolor);
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.banner-form_loc {
  background: var(--white);
  border-radius: 6px;
  padding: 1rem;
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.borderRadius_1 {
  border-radius: 6px !important;
}
/* .banner-form .select_as_btn option {
  background: var(--white);
  color: var(--primarycolor);
  border: 0;
  font-size: 16px;
  font-weight: 500;
  padding: 1rem 0;
}
.banner-form .select_as_btn option:hover {
  background: var(--black);
  color: var(--white);
} */

.banner-form .select_as_btn:hover,
.banner-form .select_as_btn2:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.banner-form .select_as_btn:hover,
.banner-form .select_as_btn:focus {
  background: var(--primarycolor);
  color: var(--white);
}

.banner-form .select_as_btn2 {
  padding: 1rem 2.5rem !important;
  background: transparent;
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
  border: 1px solid var(--white);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
/* .banner-form .select_as_btn2 option {
  background-color: var(--white);
  color: var(--primarycolor);
  border: 0;
  font-size: 16px;
  font-weight: 500;
  margin: 1rem 0;
}
.banner-form .select_as_btn2 option:hover {
  background-color: var(--white);
  color: var(--primarycolor);
} */

.banner-form .select_as_btn2:focus,
.banner-form .select_as_btn2:hover {
  background: transparent;
  /* color: var(--primarycolor); */
  color: var(--white);
  border: 1px solid var(--primarycolor);
}

.banner-form .banner_btn_wrape {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  /* margin-bottom: 1rem; */
  flex-wrap: wrap;
}
.banner-form .banner_btn_wrape .banner_btn {
  padding: 0.7rem 2rem;
  background: var(--black);
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  box-shadow: none;
  border-radius: 0;
}
.banner-form .banner_btn_wrape .banner_btn:focus,
.banner-form .banner_btn_wrape .banner_btn:hover {
  border-color: 1px solid var(--black);
  background: transparent;
  color: var(--black);
  outline: 0;
  box-shadow: none;
}
.banner-form .banner-btn {
  padding: 0.5rem 1rem;
  /* border-radius: 100px; */
  background: var(--black);
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}
.banner-form .banner-btn:focus,
.banner-form .banner-btn:hover {
  border-color: 1px solid var(--black);
  background: transparent;
  color: var(--black);
}
.spring-animation {
  width: 100%;
  height: 100%;
}
.spring-animation img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.banner-div-content {
  animation: fadeInleft 0.5s ease-in-out;
  position: relative;
  z-index: 2;
  text-align: left;
}
.banner-div-content h6 {
  font-weight: 400;
  font-size: 35px;
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  color: var(--primarycolor);
}
.banner-div-content h1 {
  font-weight: 600;
  font-size: 65px;
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;

  color: var(--white);
  text-transform: capitalize;
}
.consult-bn,
.about-bn,
.view-more,
.submit-bn,
.subcribe,
.view-products,
.ad-cart-bn,
.sub-re,
.edit-bn1,
.checkout-bn,
.form-wizard-next-btn,
.form-wizard-previous-btn,
.form-wizard-submit,
.cart-drop-bn,
.cp-bn,
.expl-bn {
  background: var(--primarycolor);
  color: var(--white) !important;
  display: inline-block;
  margin: 0;
  margin: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 18px;
  margin-right: 8px;
  padding: 10px 20px;
  transition: all 0.5s;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0 !important;
}

.dropdown:hover > .dropdown-menu1 {
  display: block !important;
  top: 100% !important;
  height: inherit;
  transform: skewY(-10px) !important;
  visibility: visible !important;
  opacity: inherit !important;
  top: 75%;
  transition: top 0.8s ease !important;
}

.dropdown-toggle:focus {
  border: 0 !important;
}
.dropdown-mega .dropdown-toggle::after {
  content: "\f107";
  font-family: "FontAwesome";
  top: 10px;
  right: -7px;
  color: var(--white);
  font-size: 13px;
  border: none;
  position: absolute;
  display: none;
}
.mega-menu-a {
  position: relative;
}
.dropdown-mega::after {
  display: none;
}
.right-menu .cart-ps-details a {
  margin: 0 !important;
  color: var(--black) !important;
  font-size: 13px !important;
  line-height: normal !important;
}
.close-crt svg {
  color: var(--black) !important;
  cursor: pointer;
  transition: all 0.5s;
}
.close-crt:hover svg {
  color: var(--primarycolor) !important;
}

.sub-total-products {
  padding: 10px 15px;
}
.news-sec-div-part .item {
  padding: 11px;
  display: inline-block;
  width: 100%;
}
.sub-total-products h4 {
  color: var(--primarycolor);
  font-weight: 600;
}
.products-sm-pic {
  margin: 0 !important;
}
.cart-drop-bn {
  width: 90%;
  margin: 0 auto;
  font-weight: 600;
  display: table;
}
.check-drop-bn {
  background: #0d0d0e !important;
  width: 90%;
  margin: 0 auto;
  font-weight: 600;
  display: table;
  color: var(--white) !important;
  margin-top: 10px !important;
  border-radius: 0 !important;
}
.big-img {
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  left: -40px;
  bottom: -40px;
}
.big-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Prodcuts-div {
  /* background: #0f0b0a url("./images/bg-wine.jpg") no-repeat; */
  background: var(--black) url("./images/bgimages/bestsellingbg.png") no-repeat;
  position: relative;
  display: inline-block;
  width: 100%;
}
.comon-heading {
  position: relative;
}
.comon-heading:after {
  display: none;
  position: absolute;
  bottom: -35px;
  content: "-------";
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 4px;
  left: 0;
  right: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
  color: var(--white);
}

.Prodcuts-div h6 {
  color: var(--white);
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 26px;
  font-style: italic;
}
.produc-div {
  padding: 10px;
  display: inline-block;
  min-height: 420px;
  overflow: hidden;
  /* background: #282828; */
  width: 100%;
}
.products-box {
  height: 290px;
  overflow: hidden;
  display: grid;
  align-content: center;
  position: relative;
  z-index: 1;
}
.products-box figure {
  height: 290px;
  overflow: hidden;
  cursor: pointer;
}
.products-box figure img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}
.products-box figure:hover img {
  transform: scale(1.15);
}
.hover-show-bn {
  position: absolute;
  background: rgba(0, 0, 0, 30%);
  height: 290px;
  width: 100%;
  text-align: right;
  display: flex;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  top: -290px;
  transition: all 0.5s;
  cursor: pointer;
}

.hover-show-bn a {
  background: var(--white);
  color: var(--black);
  margin: 0 6px;
  padding: 0 14px;
  font-size: 20px;
  line-height: 39px;
  display: grid;
  align-content: center;
  height: 47px;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 100px;
}
.hover-show-bn a:hover {
  color: var(--primarycolor);
}
.user-dsl h6 {
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.user-dsl h6 span {
  color: #bbb;
  font-size: 15px;
  font-weight: 600;
}
.comon-description-products h3 {
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.produc-div:hover .hover-show-bn {
  top: 0;
}
.review-form h2 {
  font-size: 40px !important;
}
.review-form .form-control {
  margin-bottom: 25px;
  border-radius: 0;
  border: 1px solid #d4dadd;
  background: var(--formbgcolor);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}
.review-form .form-control:focus {
  box-shadow: none;
  border: 1px solid var(--primarycolor);
}
.review-form textarea {
  height: 150px !important;
  resize: none;
}
.cate-text {
  color: var(--secondarycolor);
  text-decoration: none;
  text-transform: capitalize;
}

.submit {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0.7rem 3rem !important;
  border-radius: 0 !important;
}
.products-titel {
  color: var(--white);
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  transition: all 0.5s;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.products-titel:hover {
  color: var(--primarycolor);
}
.price-text {
  color: var(--white);
  font-weight: 700;
  font-size: 14px;
}
.old-pice {
  font-size: 10px;
  color: var(--secondarycolor);
  font-weight: 600;
}
.icon-sp {
  width: 70px;
  height: 70px;
  display: grid;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 1px 22px 0 rgb(0 0 0 / 12%);
  padding: 11px;
}
.icon-sp img {
  margin: auto;
  width: 87%;
  transform: scale(1);
  transition: all 0.5s;
}
.middle-big-pic {
  width: 95%;
  margin: auto;
  /* height: 540px; */
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.middle-big-pic img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.comon-special h5 {
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 24px;
  font-weight: 600;
  transition: all 0.5s;
}
.comon-special:hover h5 {
  color: var(--primarycolor);
}
.comon-special:hover .icon-sp img {
  transform: scale(1.1);
}
.choose-bread h6,
.our-production-div h6,
.testimonal-sec h6,
.news-sec-div h6 {
  color: var(--primarycolor);
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 26px;
}

.offer-div-sec {
  /* background: url("./images/bgimages/royalDiscBg.webp") no-repeat; */
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("./images/bgimages/royalDiscBg.webp") center center no-repeat;
  background-size: cover;
  width: 100%;
}
.comon-our-text figure {
  width: 65px;
  display: grid;
  align-content: center;
  margin: auto;
  margin-bottom: 10px;
  /* background: var(--white); */
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  /* padding: 11px; */
}
.comon-our-text figure img {
  width: 87%;
  margin: auto;
  text-align: center;
  display: block;
}
.comon-our-text h5 {
  position: relative;
  font-size: 26px;
  font-weight: 600;
  font-family: "Playfair", sans-serif;
}

.comon-our-text h5:after {
  display: none;
  position: absolute;
  bottom: -20px;
  content: "-------";
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 4px;
  left: 0;
  right: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Playfair", sans-serif;
  color: var(--white);
}
.comon-our-text p {
  /* margin-top: 39px; */
  margin-top: 15px;
  font-size: 15px;
}
.our-production-div h2 {
  position: relative;
}
.our-production-div h2:after {
  display: none;
  position: absolute;
  bottom: -35px;
  content: "-----";
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 4px;
  left: 0;
  right: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
  color: rgb(20, 20, 20);
}

.our-production-div .produc-div {
  /* border: none; */
  /* box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 10%);
   background: var(--white); */
}
.our-production-div .products-titel,
.our-production-div .price-text {
  color: #212529;
}
.our-production-div .products-titel {
  font-weight: 500;
  margin-top: 6px !important;
}

/* ******Join Club Css******** */
.join-club {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.32),
      rgba(0, 0, 0, 0.63)
    ),
    url("./images/bgimages/joinClubBgImg.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  padding: 50px 0;
}
.join-club h2 {
  /* font-size: 45px !important; */
  margin-top: 1rem;
  color: var(--white);
}
.join-club p {
  font-size: 18px;
  font-weight: 500;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  color: var(--white);
}
.join-club .form-control {
  background: var(--formbgcolor);
  color: var(--black);
  border-radius: 0;
  font-size: 16px;
  font-weight: 600;
}
.join-club .form-control:focus {
  box-shadow: none;
  border: 1px solid var(--primarycolor);
}
.join-club .join-btn {
  background: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  color: var(--white);
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.7rem 4rem;
  border-radius: 0;
}

.join-club .join-btn:focus,
.join-club .join-btn:hover {
  background: var(--white);
  color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}

.join-club .upload_doc_wrape {
  background: var(--formbgcolor);
  padding: 2rem;
}

.join-club .upload_doc {
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 1rem;
}
.join-club .upload_doc p {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
}
.join-club .upload_doc ul li {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
}
.join-club h3 {
  font-size: 15px;
  font-weight: 600;
  color: var(--black);
  text-align: center;
}
.join-club h3 span {
  border: 1px dotted var(--black);
  border-radius: 100px;
  margin-right: 0.5rem;
  padding: 0.35rem 0.73rem;
}
.join-club hr {
  border-color: var(--primarycolor);
  opacity: 1;
}
.join-club .btn_style1 {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  color: var(--white);
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  width: 100%;
}
.join-club .btn_style1:hover,
.join-club .btn_style1:focus {
  background-color: var(--white);
  color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.join-club .note_text {
  font-size: 15px;
  font-weight: 400;
}
.join-club .note_text a {
  text-decoration: none;
  color: var(--primarycolor);
  font-weight: 500;
}
.testimonal-sec {
  overflow: hidden;
}
.tesimonial-sec-div {
  position: absolute;
  top: 0;
  right: -220px;
  z-index: -1;
}
.silder-div-text .item {
  display: inline-block;
  padding: 15px;
  margin: 20px 0;
}
.silder-div-text .item .insideu-div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  padding: 15px;
  background: var(--white);
  /* height: 300px; */
  height: 100%;
}
.slick-dots li button:before {
  font-size: 12px !important;
  color: #d6d6d6 !important;
  opacity: 1 !important;
}
.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #869791 !important;
}
#best-sl-m {
  position: relative;
  z-index: 2;
}
.silder-div-text .item h5 {
  font-size: 16px;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
}
.silder-div-text .item h4 {
  font-size: 16px;
}
.silder-div-text .item h4 span {
  color: var(--secondarycolor);
  padding-top: 5px;
}
.news-sec-div {
  background: #f5f5f5 url("./images/601976e1873df1002150c789_optimized.png")
    520px center no-repeat;
  position: relative;
  width: 100%;
}
.comon-news-part {
  background: var(--white);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  height: 100%;
  overflow: hidden;
}
.dtails-idv-text {
  padding: 1.5rem 1rem;
}
.comon-pic-news {
  height: 260px;
  overflow: hidden;
  position: relative;
}
.comon-pic-news figure {
  height: 260px;
}
.comon-pic-news figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.date-text {
  background: #2f3336;
  padding: 10px 20px;
  color: var(--white);
  position: absolute;
  display: grid;
  align-content: center;
  left: 10px;
  top: 10px;
  text-align: center;
  /* font-family: "Barlow Condensed", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 25px;
}
.DeskTopFIlter {
  display: block;
}
.SHop_Resp {
  display: none;
  position: fixed;
  bottom: 0;
  left: -100%;
  z-index: 9999;
  background-color: white;
  width: 90%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: 20px;
  transition: 0.3s;
}
.SHop_RespShow {
  left: 0;
  transition: 0.3s;
}
.CloseFIlter {
  cursor: pointer;
  display: flex;
  justify-content: end;
  align-content: center;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
}
.FIlterBnts {
  display: none;
}
.date-text span {
  font-weight: 400;
  font-size: 17px;
}
.dtails-idv-text ul li {
  text-decoration: none;
  color: #2f3336;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;
}
.dtails-idv-text ul li svg {
  color: var(--secondarycolor);
  margin-right: 5px;
}
.dtails-idv-text ul li {
  margin-right: 12px;
}
.dtails-idv-text ul li i {
  color: var(--primarycolor);
  font-size: 14px;
  margin-right: 8px;
}
button.comon-filter-bn:focus:not(:focus-visible) {
  outline: 0;
  background: var(--primarycolor) !important;
}
button.comon-filter-bn:active {
  background: var(--primarycolor) !important;
}
.dtails-idv-text h5 {
  /* font-family: "Quicksand", sans-serif; */
  font-family: "PlayFair", sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  font-weight: 600;
  font-size: 26px;
}
.read-more-bn {
  /* background: var(--primarycolor) !important; */
  background: var(--black) !important;
  color: var(--white) !important;
  border-radius: 0;
  padding: 0.7rem 2rem !important;
}

/* ********* conatct Map*********** */
.contact-map-sec {
  /* background: linear-gradient(to left, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.65)),
    url("./images/bgimages/discoverBg.jpg") center center no-repeat;
  background-size: cover; */
}
.contact-map-sec .map-bg-img {
  background: url("./images/bgimages/contactMapBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
}
.contact-map-sec .contact_details {
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  width: 65%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.contact-map-sec .contact_details h6 {
  color: var(--primarycolor);
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 26px;
}
.contact-map-sec .contact_details h2 {
  color: var(--white);
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.contact-map-sec .contact_details p,
.contact-map-sec .contact_details ul li a {
  font-size: 14px;
  font-weight: 500;
  color: var(--primarycolor);
  text-decoration: none;
}
.contact-map-sec .contact_details ul li a:hover {
  color: var(--white);
}

.contact-map-sec .contact_bottom h6 {
  color: var(--primarycolor);
  font-family: "Playfair", sans-serif;
  font-size: 26px;
  font-style: italic;
  margin-bottom: 0.5rem;
}
.contact-map-sec .contact_bottom h2 {
  margin-bottom: 1rem;
  color: var(--black);
  /* text-align: center; */
}
.contact-map-sec .contact_bottom h2 span {
  font-family: "Playfair", sans-serif;
  font-style: italic;
}
.contact-map-sec .contact_bottom .subtitle {
  font-size: 16px;
  color: var(--black);
  text-align: justify;
  margin-bottom: 1rem;
  /* text-align: center; */
}
.contact-map-sec .contact_bottom p {
  font-size: 16px;
  color: var(--black);
  text-align: justify;
  margin-bottom: 0.5rem;
  /* text-align: center; */
}

.contact-map-sec figure {
  height: 400px;
  overflow: hidden;
  border-radius: 5px;
}
.contact-map-sec figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

footer {
  background: var(--black);
  /* background: url("./images/logo/footerbg.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover; */
}
.app_icons img {
  width: 130px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.app_icons a {
  text-decoration: none;
  color: var(--black);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.app_icons a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.comon-footer h5 {
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: "PlayFair", sans-serif;
}
.comon-footer ul li {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--lightwhite);
}
.comon-footer ul li b {
  color: var(--white);
}
.comon-footer ul li a {
  color: var(--lightwhite);
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition-duration: 0.3s;
}
.comon-footer ul li a:hover {
  padding-left: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: var(--primarycolor);
}
.comon-special {
  margin-bottom: 25px;
}
.comon-footer .socialIcons li {
  display: flex;
}
.comon-footer .socialIcons li a {
  margin-right: 0.5rem;
  border-radius: 19.2px;
  background: #ccc;
  color: #444;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.comon-footer .socialIcons li a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  padding-left: 0;
  color: var(--white);
  background: var(--primarycolor);
}

.comon-footer .socialIcons li a:last-child {
  margin-right: 0;
}

.comon-footer .socialIcons li a .icon-style {
  font-size: 16px;
}

.comon-footer .subscriber-wraper .form-control {
  width: 100%;
  background: transparent;
  border: 1px solid var(--lightwhite);
  border-radius: 0;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem 1rem !important;
}

.comon-footer .subscriber-wraper .form-control::placeholder {
  color: var(--lightwhite) !important;
}

.comon-footer .subscriber-wraper .form-control:focus {
  border: 1px solid var(--primarycolor);
  box-shadow: none;
}

.comon-footer .subscriber-wraper .subscribe-btn {
  border-radius: 0;
  background: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  color: var(--white);
  font-weight: 400;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
  font-size: 14px;
}

.comon-footer .subscriber-wraper .subscribe-btn:focus,
.comon-footer .subscriber-wraper .subscribe-btn:hover {
  border-color: 1px solid var(--primarycolor);
  background: transparent;
  color: var(--primarycolor);
}

.space_line {
  color: var(--white);
  opacity: 1;
  margin-bottom: 0;
}
.copyright-wraper .copyright-text {
  color: var(--lightwhite);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.copyright-wraper .copyright-text a {
  text-decoration: none;
  color: var(--white);
}
.copyright-wraper .copyright-text a:hover {
  color: var(--primarycolor);
}
.copyright-wraper .copyright-text b {
  color: var(--white);
}

/* new css end */

/* only banner */
.css-slider-wrapper {
  display: block;
  background: var(--white);
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Slider */
.slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  -webkit-transition: -webkit-transform 1600ms;
  transition: -webkit-transform 1600ms, transform 1600ms;
  -webkit-transform: scale(1);
  transform: scale(1);
  background-size: cover;
}

/* Slides Background Color */
.slide-1 {
  background: #201e1f;

  left: 0;
}
.slide-2 {
  background: #201e1f;
  left: 100%;
}
.slide-3 {
  background: #201e1f;
  left: 200%;
}
.slide-4 {
  background: #b1a494;
  left: 300%;
}
.slider {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.slider-content {
  width: 637px;
  padding-right: 100px;
  margin-left: auto;
}

/* Slider Inner Slide Effect */
.slider h2 {
  /* text-transform: capitalize; */
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 65px;
  font-weight: 600;
  line-height: 1.2;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
  margin-top: 0;
  color: var(--primarycolor);
}
.slider h2 span {
  color: var(--white);
}

.slider h4 {
  font-size: 32px;
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  color: var(--white);

  text-transform: capitalize;
  line-height: 1.2;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}
.slider-content p {
  color: var(--white);
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  position: relative;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.slider button {
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.slider > .img-div {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  top: 0;
}

.items-pic10 {
  position: absolute;
  right: 15px;
  bottom: 50px;

  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.buy-now-btn {
  background: #fcfcfc;
  background: -moz-linear-gradient(top, #fcfcfc 0%, #e5e5e5 100%);
  background: -webkit-linear-gradient(top, #fcfcfc 0%, #e5e5e5 100%);
  background: linear-gradient(to bottom, #fcfcfc 0%, #e5e5e5 100%);

  color: #2c2b30;
  width: 190px;
  height: 50px;
  border-radius: 0;
  border: none;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;

  text-align: left;

  position: relative;
  cursor: pointer;

  box-shadow: 0px 21px 10px -10px rgb(0 0 0 / 31%);
  cursor: pointer;
  transition: all ease-in-out 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.buy-now-btn:hover {
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0px, -10px) scale(1.1);
}
.buy-now-btn svg {
  margin-right: 5px;
}

.slider .buy-now-btn:focus,
.navigation .login-btn:focus {
  outline: none;
}

/* Animations */
.slider h2 {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1s; /* Safari */
  transition-delay: 1s;
}
.slider button {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1s; /* Safari */
  transition-delay: 1s;
}
.slider h4 {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1.4s; /* Safari */
  transition-delay: 1.4s;
}
.slider p {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1.4s; /* Safari */
  transition-delay: 1.4s;
}

.slide-1 > .slide-up {
  -webkit-animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(0);
  }
}

.items-pic10 {
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
  transition: transform 500ms, opacity 500ms;
  -webkit-transition-delay: 1.5s; /* Safari */
  transition-delay: 1.5s;
}

/* Number Pagination */
.number-pagination {
  position: absolute;
  bottom: 30px;
  right: 100px;
  font-family: "Oswald";
  font-weight: bold;
}

.number-pagination span {
  font-size: 30px;
  color: #ea2e49;
  letter-spacing: 4px;
}

.number-pagination span:after {
  content: "/4";
  font-size: 16px;
  color: var(--white);
}

/* Slider Pagger */
.slider-pagination {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 30px;
  z-index: 20;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
}
.slider-pagination label {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: var(--secondarycolor);
  margin: 0 10px;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.7;
}
.slider-pagination label figure {
  margin-bottom: 0;
}
.slider-pagination label figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Slider Pagger Event */
.slide-radio1:checked ~ .slider-pagination .page1,
.slide-radio2:checked ~ .slider-pagination .page2,
.slide-radio3:checked ~ .slider-pagination .page3,
.slide-radio4:checked ~ .slider-pagination .page4 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;

  margin: 0 10px;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.9;
}

/* Slider Slide Effect */
.slide-radio1:checked ~ .slider {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.slide-radio2:checked ~ .slider {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.slide-radio3:checked ~ .slider {
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
}
.slide-radio4:checked ~ .slider {
  -webkit-transform: translateX(-300%);
  transform: translateX(-300%);
}
.slider h4 {
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
}

.range-val .SliderInput {
  width: 60px;
  font-size: 14px;
  border: 2px solid var(--primarycolor);
  outline: none;
  padding: 5px;
  color: black;
  font-weight: 600;
}
.range-val .SliderInput:focus {
  color: var(--primarycolor);
}
.slide-radio1:checked ~ .slide-1 h2,
.slide-radio1:checked ~ .slide-1 p,
.slide-radio1:checked ~ .slide-1 button,
.slide-radio2:checked ~ .slide-2 h2,
.slide-radio2:checked ~ .slide-2 p,
.slide-radio2:checked ~ .slide-2 button,
.slide-radio3:checked ~ .slide-3 h2,
.slide-radio3:checked ~ .slide-3 button,
.slide-radio3:checked ~ .slide-3 .items-pic10,
.slide-radio4:checked ~ .slide-4 h2,
.slide-radio1:checked ~ .slide-1 h4,
.slide-radio2:checked ~ .slide-2 h4,
.slide-radio3:checked ~ .slide-3 h4,
.slide-radio3:checked ~ .slide-3 p,
.slide-radio4:checked ~ .slide-4 h4,
.slide-radio1:checked ~ .slide-1 > .img-div,
.slide-radio2:checked ~ .slide-2 > .img-div,
.slide-radio3:checked ~ .slide-3 > .img-div,
.slide-radio4:checked ~ .slide-4 > .img-div,
.slide-radio1:checked ~ .slide-1 > .chill,
.slide-radio2:checked ~ .slide-2 > .chill,
.slide-radio3:checked ~ .slide-3 > .chill,
.slide-radio1:checked ~ .slide-1 > .tomato,
.slide-radio2:checked ~ .slide-2 > .tomato,
.slide-radio3:checked ~ .slide-3 > .tomato,
.slide-radio1:checked ~ .slide-1 > .leaf-pic1,
.slide-radio1:checked ~ .slide-1 > .leaf-pic2,
.slide-radio1:checked ~ .slide-1 > .leaf-pic3,
.slide-radio2:checked ~ .slide-2 > .leaf-pic1,
.slide-radio2:checked ~ .slide-2 > .leaf-pic2,
.slide-radio2:checked ~ .slide-2 > .leaf-pic3,
.slide-radio3:checked ~ .slide-3 > .leaf-pic1,
.slide-radio3:checked ~ .slide-3 > .leaf-pic2,
.slide-radio3:checked ~ .slide-3 > .leaf-pic3 {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}
.slide-radio1:checked ~ .slide-1 > .chill {
  opacity: 1;
}
.slide-radio2:checked ~ .slide-2 > .slide-up {
  opacity: 1;
  -webkit-animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
}
.comon-offer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  display: grid;
  align-content: center;
  background: #0d0d0e;
  text-align: left;
}
.comon-offer figure {
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: 0;
}
.comon-offer figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* object-position: 0% 50%; */
  /* opacity: 0.8; */
  opacity: 1;
  transform: scale(1);
  transition: all 0.5s;
}
.height-offer {
  height: 424px;
}
.height-offer figure {
  height: 424px;
}
.comon-offer:hover figure img {
  transform: scale(1.1);
  /* opacity: 0.2; */
  opacity: 1;
}
.OfferMr2 {
  object-position: right center;
  object-position: 0% 50%;
}
.comon-offer h5 {
  color: var(--white);
  /* font-family: "Barlow Condensed", sans-serif; */
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
  z-index: 1;
  top: 23%;
  text-shadow: -8px -4px 11px rgba(0, 0, 0, 0.82);
  transform: translateY(-50%);
  font-size: 18px;
}
.comon-offer h5 span {
  display: none;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 0.5rem;
}
.OfferStoreLogo {
  width: 120px;
}
.offer-text-div {
  background: var(--secondarycolor);
  color: var(--white);
  position: absolute;
  left: 10px;
  /* top: 10px; */
  top: 5px;
  border-radius: 5px;
  /* font-size: 14px; */
  font-size: 10px;
  width: 75px;
  text-align: center;
  /* line-height: 34px; */
  line-height: 26px;
  font-weight: 600;
  z-index: 1;
}
.wish_prod_icon {
  color: var(--black);
  position: absolute;
  right: 10px;
  /* top: 10px; */
  top: 5px;
  font-size: 18px;
  z-index: 1;
  cursor: pointer;
}
.discover-div-sec {
  background: #efefec;
}
.discover-div-sec h6 {
  color: var(--primarycolor);
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 26px;
}
.comon-cate {
  position: relative;
}
.comon-cate {
  background: var(--black);
  height: 240px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.comon-cate figure {
  margin: auto;
  display: block;
  height: 240px;
  width: 100%;
  overflow: hidden;
}
.comon-cate figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transform: scale(1);
  transition: all 0.5s;
}
.comon-cate:hover figure img {
  transform: scale(1.1);
  opacity: 0.5;
}

.comon-cate h5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  color: var(--white);
  /* font-family: "Barlow Condensed", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  text-shadow: var(--black) 1px 1px 8px;
  text-align: center;
}

/* **********sub page banner css******** */

.subpage-banner {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 400px;
}
.cstList {
  list-style-image: url("./images/logo/icon.svg") !important;
  list-style-position: outside !important;
  padding-left: 1.5rem !important;
}
.sub-page-content h1 {
  /* color: var(--primarycolor); */
  color: var(--bgcolor);

  text-transform: capitalize;
  font-family: "Playfair", sans-serif;
  font-weight: 600;
  font-size: 45px;
}
.sub-page-content h1 span {
  font-family: "Playfair", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-transform: initial;
}
.sub-page-content ul li {
  margin: 0 5px;
  color: var(--white);
  font-size: 17px;
  text-transform: capitalize;
}
.sub-page-content ul li a {
  color: var(--white);
  text-decoration: none;
  /* text-transform: capitalize; */
}

.page-breakcrumb h1 {
  /* color: var(--primarycolor); */
  color: var(--black);

  text-transform: capitalize;
  font-family: "Playfair", sans-serif;
  font-weight: 600;
  font-size: 45px;
}
.page-breakcrumb h1 span {
  font-family: "Playfair", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-transform: initial;
}
.page-breakcrumb ul li {
  margin: 0 5px;
  color: var(--black);
  font-size: 15px;
  text-transform: capitalize;
}
.page-breakcrumb ul li.active {
  color: var(--primarycolor);
}
.page-breakcrumb ul li a {
  color: var(--black);
  text-decoration: none;
  /* text-transform: capitalize; */
}
.number {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: var(--white);
  padding: 12px;
}

/* ******** quantity Count css****** */
.count_wrape {
  background: var(--white);
  border-radius: 0;
  font-size: 17px;
  padding: 0.5rem 1rem;
}
.wishlist_filled {
  fill: red;
}
.count_wrape .quant_icon {
  font-size: 12px;
  cursor: pointer;
}

/* products details */

/************** products Slider********** */

.products-slide-1 .image-gallery-thumbnail.active,
.products-slide-1 .image-gallery-thumbnail:focus,
.products-slide-1 .image-gallery-thumbnail:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.menu-dl-right h2 {
  /* text-transform: capitalize; */
  line-height: 41px !important;
}
.menu-dl-right h3 {
  font-weight: 700;
  color: var(--primarycolor);
  font-size: 28px;
}
.menu-dl-right h5 {
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}
.menu-dl-right .old-pice {
  font-size: 10px;
  color: rgb(77, 77, 77);
  font-weight: 600;
}
.share-links li {
  display: flex;
}
.share-links li button {
  margin-right: 0.3rem;
}

.share-links li button:last-child {
  margin-right: 0;
}
.cart-bn-ds-wrape {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.cart-btn_main {
  /* background: var(--primarycolor) !important; */
  background: var(--black) !important;
  color: var(--white) !important;
  font-size: 16px !important;
  font-weight: 600;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  /* padding: 0.4rem 2rem !important; */
  width: 90%;
  border-radius: 0;
  border: none;
}
.cart-btn_main_bestSelling {
  background: var(--white) !important;
  color: var(--black) !important;
}
.trackBtn {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
  font-size: 16px !important;
  font-weight: 600;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  padding: 0.4rem 0.5rem !important;
  border-radius: 0;
  margin-top: 10px;
}
.cart-btn_main_Comp {
  font-size: 14px !important;
}
.cart-bn-ds {
  /* background: var(--primarycolor) !important; */
  background: var(--black) !important;
  color: var(--white) !important;
  font-size: 16px !important;
  font-weight: 600;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  padding: 0.7rem 2rem !important;
  border-radius: 0;
}
.cart-bn-ds .cart_icon {
  font-size: 16px;
  margin-right: 0.5rem;
}
.wish-bn-ds {
  background: var(--white) !important;
  color: var(--black) !important;
  font-size: 16px !important;
  font-weight: 600;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  padding: 0.7rem 2rem !important;
  border-radius: 0;
}
.wish-bn-ds .cart_icon {
  font-size: 16px;
  margin-right: 0.5rem;
}
.bottom-details {
  margin-top: 50px;
}
.bottom-details .nav-tabs .nav-link {
  background: var(--white) !important;
  border: none !important;
  margin-right: 10px;
  text-transform: capitalize;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "PlayFair", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: var(--black);
  padding: 1rem 2rem;
}
.bottom-details .nav-tabs {
  border: none;
}
.bottom-details .tab-content {
  background: var(--white);
  padding: 20px;
}
.bottom-details .nav-tabs .nav-link.active {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
}
.related-products {
  position: relative;
}
.comon-relate-items .price-text {
  color: var(--black);
}
.sub-pages {
  background: #f0f0f0;
}
.comon-description-products {
  text-align: left;
}
.comon-description-products p {
  font-size: 16px;
}
.comment-user-div {
  display: flex;
  flex-wrap: wrap;
  border-bottom: solid 1px #f1f1f1;
  padding-bottom: 20px;
  padding-top: 20px;
}
.comment-user-div .us-pic {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
}
.user-dsl {
  width: 80%;
}
.comon-relate-items .products-titel {
  color: var(--black);
  font-weight: 500;
}
.comon-relate-items {
  background: var(--white);
}
.owl-prev:after {
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  content: "\f100";
  left: 40%;
  transform: translateX(-40%);
  margin: auto;
  font-family: "FontAwesome";
  color: var(--white);
  display: grid;
  background: var(--primarycolor);
  align-content: center;
  font-size: 20px;
}
.owl-next:after {
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  content: "\f101";
  left: 60%;
  transform: translateX(-60%);
  margin: auto;
  font-family: "FontAwesome";
  color: var(--white);
  display: grid;
  background: var(--primarycolor);
  align-content: center;
  font-size: 20px;
}
.owl-prev span {
  display: none;
}
.owl-next span {
  display: none;
}
.DeskImgBanner {
  display: block;
}
.mobileImgBanner {
  display: none;
}
/* list grid js */
.item.list-group-item {
  float: none;
  width: 100%;
  margin-bottom: 30px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
  border: 0;
  height: 243px;
  overflow: hidden;
  border-radius: 8px;
}
.comon-items-d1 {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--white);
  /* box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%); */
  border-radius: 8px;
}
.item.list-group-item .products-img-div {
  width: 40%;
  position: relative;
  overflow: hidden;
  height: 211px;
}
.comon-items-d1 .card-body {
  padding-top: 0;
  padding-bottom: 0;
  height: 211px;
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: baseline;
}
.grid-group-item {
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 8px;
}
.grid-group-item .list-img-div {
  height: 230px;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  position: relative;
}
.grid-group-item .comon-items-d1 .card-body {
  height: auto;
  padding: 0;
  padding-top: 15px;
  align-items: center;
}
.cate-list {
  background: none;
  border: none;
}
.cate-list li {
  border: none;
  border-bottom: solid 1px var(--white);
  padding-left: 0;
  padding-right: 0;
  background: none;
}
.cate-list li:last-child {
  border-bottom: none;
}
.cate-list .rounded-pill {
  background: var(--primarycolor) !important ;
}
#more-link {
  color: var(--primarycolor) !important;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
#more-div-text {
  display: none;
}
.cate-list li a {
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  text-decoration: none;
  font-size: 16px;
}
.sel-size .btn-group {
  display: block;
}
.sel-size .btn-group .btn {
  margin: 5px 10px;
  border-radius: 0 !important;
  color: var(--black) !important;
  border-color: var(--white) !important ;
  background: var(--white);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  margin-left: 0 !important;
}

.sel-size .btn-check:active + .btn-outline-primary,
.sel-size .btn-check:checked + .btn-outline-primary,
.sel-size .btn-outline-primary.active,
.sel-size .btn-outline-primary.dropdown-toggle.show,
.sel-size .btn-outline-primary:active {
  color: var(--white) !important;
  background: var(--primarycolor) !important;
}
.products-img-div figure {
  height: 211px;
  position: relative;
}
.products-img-div figure img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin: 0;
}
.products-img-div .offer {
  background: var(--secondarycolor);
  width: 83px;
  line-height: 32px;
  display: block;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  color: var(--white);
  border-radius: 5px;
}
.comon-items-d1 .products-titel {
  color: var(--black);
  font-weight: 600;
  font-size: 14px;
}
.comon-items-d1 .price-text {
  color: var(--primarycolor);
}
.cart-pro-bn {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.cart-pro-bn svg {
  font-size: 20px;
}
.listed-bn ul li a {
  color: #212529;
  font-size: 22px;
  cursor: pointer;
}
.listed-bn ul li .active {
  color: var(--primarycolor);
}
.grid-group-item .products-img-div {
  width: 100%;
}
.grid-group-item .products-img-div figure {
  margin: auto;
}
.cart-drop-bn {
  background-color: var(--primarycolor) !important;
  margin: 0 auto;
}

/* **** empty cart */
.empty_cart .empty_cat_icon {
  font-size: 120px;
  color: var(--primarycolor);
  margin-bottom: 0.5rem;
}
.empty_cart h2 {
  font-size: 28px !important;
}
/* cart page */

.cart-left-sec {
  min-height: 270px;
  padding: 15px 20px;
}
.cart-left-sec .count_wrape {
  width: fit-content;
  gap: 30px;
  background: var(--formbgcolor);
  border-radius: 0;
}

.cart-left-sec,
.oder-total-div {
  background: var(--white);
  box-shadow: 0 1px 22px 0 rgb(0 0 0 / 9%);
}
.cart-left-sec h2,
.oder-total-div h2 {
  font-size: 30px !important;
}
.cart-left-sec hr,
.oder-total-div hr {
  background: #bbb;
}
.order-pic {
  display: inline-block;
  overflow: hidden;
  width: 123px;
  margin: auto;
  display: table;
}
.pro-sec .products-cate {
  color: var(--primarycolor) !important;
}
.products-size {
  font-size: 15px;
  font-weight: 400;
}
.cart-products-titel {
  color: #212529;
  font-weight: 600;
  margin-left: 0;
  display: block;
  margin-bottom: 0.5rem;
  line-height: normal;
  /* overflow: hidden; */
  text-decoration: none;
  /* text-overflow: ellipsis; */
  text-transform: capitalize;
  /* white-space: nowrap; */
  width: 200px;
}
.cross-bn {
  position: absolute;
  top: -5px;
  left: 0;
}
.pro-sec .number {
  padding-left: 0;
}
.comon-sec-div-mn .form-group {
  width: 30%;
}
.cross-bn:hover {
  color: var(--primarycolor) !important;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.comon-sec-div-mn .price-text {
  color: var(--primarycolor);
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.oder-total-div {
  padding: 15px;
}
.delivery-am,
.vat-am,
.discount-am,
.price-am {
  align-items: center;
  display: flex;
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  justify-content: space-between;
  font-weight: 500;
}
.price-am span,
.discount-am span {
  font-size: 16px;
  font-weight: 600;
}
.delivery-am span {
  color: var(--primarycolor);
  font-size: 16px;
  font-weight: 600;
}
.delivery-am span a {
  text-decoration: none;
  color: var(--primarycolor);
  font-size: 16px;
  font-weight: 600;
}
.vat-am {
  color: var(--secondarycolor);
  font-size: 15px;
  font-weight: 600;
}
.cp-bn {
  background: var(--primarycolor) !important;
  border-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  color: var(--white) !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  padding: 0.5rem !important;
}
.new-input {
  border-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid #d4dadd !important;
  background: var(--formbgcolor) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: var(--black) !important;
  padding: 0.5rem 1rem !important;
}
.new-input:focus {
  box-shadow: none !important;
  border: 1px solid var(--primarycolor) !important;
  background: var(--white) !important;
}
.total-price {
  color: var(--primarycolor);
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
}
.total-price p {
  font-size: 18px;
  font-weight: 600;
}
.paybn {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
  border-radius: 0 !important;
  padding: 0.7rem 3rem !important;
}
.CancelAddresBtn {
  background: var(--black) !important;
  color: var(--white) !important;
  border-radius: 0 !important;
  padding: 0.7rem 3rem !important;
  transition: 0.4s;
}
.CancelAddresBtn:hover {
  background: var(--primarycolor) !important;
  transition: 0.4s;
}
.order-pic img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.comon-sec-div-mn {
  background: var(--white);
  border-bottom: 1px solid #eee;
  display: inline-block;
  padding-top: 15px;
  width: 100%;
  padding-bottom: 15px;
}
.tbale-cart > div:last-child {
  border-bottom: none;
}

.wishlist_wrape {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.cart-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.cart-btn .continue-shopping-btn {
  background-color: var(--white);
  border: 1px solid var(--primarycolor) !important;
  color: var(--primarycolor);
  border-radius: 0;
}
.cart-btn .continue-shopping-btn:hover {
  color: var(--white);
  background-color: var(--primarycolor);
}

/* cart page css ends */

/* checkout page */

.checkout-right {
  background: var(--white);
  box-shadow: 0 1px 22px 0 rgb(0 0 0 / 9%);
  padding: 15px 20px;
}
.checkout-right h2,
.left-checkout h2 {
  font-size: 35px !important;
}
.checkout-table tr th {
  background: #f7f7f7;
  border: none !important;
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
}

.checkout-table td {
  font-size: 15px;
}
.checkout-table td p {
  font-size: 14px;
  margin: 0;
}
.checkout-table tr:last-child td {
  border: none;
}
.left-checkout {
  background: var(--white);
  box-shadow: 0 1px 22px 0 rgb(0 0 0 / 9%);
  padding: 15px 20px;
}
.left-checkout .form-control,
.left-checkout .form-select {
  border-radius: 0;
  border: 1px solid #d4dadd;
  background: var(--formbgcolor);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}
.left-checkout .form-control:focus,
.left-checkout .form-select:focus {
  box-shadow: none;
  border: 1px solid var(--primarycolor);
}
.left-checkout .form-check {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}
.left-checkout .form-check .form-check-input:checked {
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
}
.left-checkout .form-check .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 188, 91, 0.25);
}
.left-checkout label {
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 8px;
  text-transform: capitalize;
}

.left-checkout label {
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 8px;
}
.left-checkout label span {
  font-weight: 600;
}

.addressListFOrm:after {
  display: table;
  content: "";
  clear: both;
}
.left-checkout textarea.form-control {
  height: 100px;
  resize: none;
}
#ac-1,
#ac-2 {
  display: none;
}
.cvv,
.end-date {
  display: flex;
  place-items: center;
}
.end-date .form-select {
  font-size: 13px;
  margin-right: 5px;
}

/* checkout css ends */

/* blog details page */

.post-img {
  display: inline-block;
  height: 350px;
  overflow: hidden;
  width: 100%;
}
.post-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.blogleft-sec ul li svg,
.share-bn svg {
  color: var(--secondarycolor);
  margin-right: 5px;
}
.blogleft-sec ul li {
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  margin: 0 10px;
}
.blog-para-details h2 {
  font-size: 36px !important;
  line-height: 41px !important;
}
.comment-sec-part h2,
.leave-commnet-sec h2 {
  font-size: 36px !important;
}
.comon-comnet-sec figure {
  border-radius: 50%;
  height: 63px;
  overflow: hidden;
  width: 63px;
  margin-right: 17px;
}
.user-cmn {
  width: 90%;
}
.comon-comnet-sec h5 span {
  color: var(--secondarycolor);
  font-size: 13px;
  margin-left: 10px;
}

.leave-commnet-sec {
  box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
  display: inline-block;
  margin: 30px 0;
  padding: 10px 20px 20px;
  width: 100%;
}

.conatct-form-div .form-control,
.leave-commnet-sec .form-control {
  margin-bottom: 15px;
  border-radius: 0;
  border: 1px solid #d4dadd;
  background: var(--formbgcolor);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}

.conatct-form-div .form-control:focus,
.leave-commnet-sec .form-control:focus {
  box-shadow: none;
  border: 1px solid var(--primarycolor);
}
.post-bn {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0.7rem 3rem !important;
  border-radius: 0 !important;
}
.leave-commnet-sec textarea {
  height: 100px !important;
  resize: none;
}
.blogcategories-div,
.recent-blog-part {
  background: #f1f1f1;
  display: inline-block;
  padding: 20px 30px;
  width: 100%;
}
.blogcategories-div h2,
.recent-blog-part h2 {
  font-size: 30px !important;
}
.blogcategories-div ul li {
  align-items: center;
  border-bottom: 1px solid var(--white);
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.blogcategories-div ul li a {
  color: var(--black);
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.5s;
}
.blogcategories-div ul li:last-child {
  border-bottom: none;
}
.comon-recent {
  border-bottom: 1px solid var(--white);
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  width: 100%;
}
.recent-all-post > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.comon-recent a h5 {
  color: var(--black);
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
  transition: all 0.5s;
}
.comon-recent a:last-child {
  color: var(--black);
  text-decoration: none;
  width: 60%;
}
.comon-recent a:first-child {
  height: 60px;
  width: 90px;
}
.comon-recent figure {
  height: 60px;
  margin: 0;
  overflow: hidden;
  width: 100%;
}
.blogcategories-div ul li a:hover {
  color: var(--primarycolor);
}
.comon-recent figure img {
  height: 100%;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s;
  width: 100%;
}
.comon-recent:hover figure img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* login modal */

.login-div-modal .modal-content,
.login-div-modal .modal-header {
  background: none;
  border: none;
}
.com-div-md {
  background: var(--white);
  padding-bottom: 25px;
  padding-top: 25px;
}
.user-icon svg {
  font-size: 35px;
}
.login-div-modal .close {
  background: none;
  border: none;
  font-size: 29px;
  position: absolute;
  right: 22px;
  top: 27px;
  -webkit-transform: none;
  transform: none;
}
.login-div-modal h5 {
  /* font-family: Barlow Condensed, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 600;
}
.login-modal-pn {
  padding: 10px 40px 30px;
}
.login-modal-pn .form-control {
  background: var(--formbgcolor);
  border: none;
  font-size: 15px;
  height: 45px;
  margin-bottom: 20px;
}
.continue-bn {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 100%;
}
.regster-bn {
  color: var(--primarycolor);
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.5s;
}

/* contact form page */

.cm-text-n h6,
.contact-form-div h6 {
  color: var(--primarycolor);
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 26px;
  font-style: italic;
}
.cm-text-n h2,
.contact-form-div h2 {
  font-size: 36px !important;
}
.cm-text-n ul li .icon-c {
  align-content: center;
  background: var(--primarycolor);
  border-radius: 50%;
  color: var(--white);
  display: grid;
  font-size: 18px;
  height: 45px;
  justify-content: center;
  overflow: hidden;
  width: 45px;
}
.cm-text-n ul li {
  margin-bottom: 20px;
}
.text-c small a {
  color: var(--primarycolor);
  font-size: 18px;
  font-weight: 600 !important;
  text-decoration: none;
}
.text-c {
  /* font-family: Quicksand, sans-serif; */
  font-family: "PlayFair", sans-serif;
  font-size: 22px;
  font-weight: 600;
  margin-left: 15px;
}
.contact-form-div {
  background: var(--white);
  box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
  display: inline-block;
  padding: 20px;
  width: 100%;
}
.cm-text-n h6,
.contact-form-div h6 {
  color: var(--primarycolor);
  font-size: 26px;
}
.contact-form-div .form-control {
  margin-bottom: 15px;
  border-radius: 0;
  border: 1px solid #d4dadd;
  background: var(--formbgcolor);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}
.contact-form-div .form-control:focus {
  box-shadow: none;
  border: 1px solid var(--primarycolor);
}
.subimt-message {
  background: var(--primarycolor) !important;
  border: 1px solid var(--primarycolor) !important;
  color: var(--white) !important;
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: uppercase;
  transition: all 0.5s;
  /* width: 150px; */
  border-radius: 0 !important;
  padding: 0.7rem 3rem !important;
}
.subimt-message:hover {
  background: var(--white) !important;
  color: var(--primarycolor) !important;
}
.mesg-fild {
  height: 100px !important;
  resize: none;
}
/* conact page css ends */
.about-sec-1 figure {
  /* height: 600px; */
  height: 385px;
  overflow: hidden;
  border-radius: 5px;
}
.about-sec-1 figure img {
  /* object-fit: contain; */
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.about-sec-1 h6,
.about-offer-section h6,
.our-staff h6,
.about-poroduction-section h6 {
  color: var(--primarycolor);
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 26px;
  font-style: italic;
}
.about-offer-section {
  background: var(--primarycolor) url("./images/bgimages/aboutus-04.jpg")
    no-repeat;
  background-position: center center;
  background-size: cover;
}
.shop-bn-ab {
  background: var(--white) !important;
  border: 1px solid var(--black) !important;
  color: var(--black) !important;
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  font-size: 16px !important;
  transition: all 0.5s;
  border-radius: 0;
  padding: 0.5rem 3rem !important;
}
.shop-bn-ab:hover {
  background: var(--black) !important;
  color: var(--white) !important;
}

.shop-now-btn {
  background: var(--white) !important;
  border: 1px solid var(--white) !important;
  color: var(--black) !important;
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  font-size: 16px !important;
  transition: all 0.5s;
  border-radius: 0;
  padding: 0.7rem 3rem !important;
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.shop-now-btn:hover {
  background: transparent !important;
  color: var(--white) !important;

  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.About-client-sec {
  background: var(--black) url("./images/bgimages/aboutus-05.jpg") no-repeat;
  background-size: cover;
}
.About-client-sec .comon-about-iocn {
  width: 65px;
  height: 65px;
  /* display: grid; */
  /* justify-content: center; */
  /* align-content: center; */
  /* overflow: hidden; */
  /* border-radius: 50%; */
  /* box-shadow: 0 1px 22px 0 rgb(0 0 0 / 12%); */
  /* padding: 11px; */
  /* background: var(--white); */
  margin: auto;
}
.comon-about-iocn img {
  margin: auto;
  width: 87%;
  transform: scale(1);
  transition: all 0.5s;
}
.comon-about h2 {
  color: var(--white);
  font-size: 32px !important;
}
.comon-about h5 {
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}
.team-item figure {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
}
.team-item h6 {
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.mobileVIew {
  display: none !important;
}
.team-item h5 {
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.about-poroduction-section ul li svg {
  color: var(--primarycolor);
  margin-right: 8px;
}
.about-poroduction-section ul li {
  /* font-family: Quicksand, sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}
.about-poroduction-section .shop-bn-ab {
  /* background: var(--primarycolor) !important; */
  /* border: 1px solid var(--primarycolor) !important; */
  background: var(--black) !important;
  border: 1px solid var(--black) !important;
  color: var(--white) !important;
}
.about-poroduction-section .shop-bn-ab:hover {
  background: var(--white) !important;
  color: var(--primarycolor) !important;
}
.about-poroduction-section figure {
  /* height: 530px; */
  height: 370px;
  overflow: hidden;
  border-radius: 5px;
}
.about-poroduction-section figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.mobile-mega-menu {
  position: relative !important;
  border: none !important;
  top: -45px !important;
}
.mobile-mega-menu .comon-menu-div li {
  border-left: 4px solid var(--primarycolor);
}
.mega-btn {
  background: var(--bgcolor) !important;
  margin-bottom: 3px;
  padding: 10px 0 10px 10px !important;
  border-radius: 0 !important;
  border: 0 !important;
}
.mega-btn:hover {
  background: var(--white) !important;
}
.mega-btn:focus {
  border: 0 !important;
  outline: 0 !important;
}

.close_btn {
  background: var(--primarycolor);
  color: var(--white);
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50px;
  padding: 0.3rem;
}
.menu-modal ul li a {
  color: var(--bgcolor);
  display: block;
  margin-bottom: 3px;
  padding: 10px 0 10px 10px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  font-weight: 600;
}
.menu-modal ul li .active {
  background: var(--primarycolor) !important;
  color: var(--bgcolor);
  font-weight: 500;
}
.menu-mobile-modal {
  width: 310px !important;
  background-color: var(--black) !important;
}
.logo-mobile {
  display: none;
}
.mbile-checkout .dropdown .mbl-cart {
  color: var(--white) !important;
  font-size: 24px;
}

.mbile-checkout .dropdown-toggle .cart-nuber {
  align-content: center;
  background: var(--primarycolor);
  border-radius: 50%;
  color: var(--white);
  display: grid;
  height: 20px;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: -2px;
  text-align: center;
  top: 20px;
  width: 20px;
  font-size: 14px;
}
.mbile-checkout .dropdown-toggle::after {
  display: none;
}
.fixed-menu {
  /* -webkit-animation: slide-down 0.7s; */
  /* animation: slide-down 0.7s; */
  /* background: var(--white); */
  background: var(--black);
  box-shadow: 4px -86px 103px rgb(0 0 0 / 90%);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 250;
}
.fixed-menu .logo-mobile {
  display: block;
}
.fixed-menu .logo-main {
  display: none;
}
.fixed-menu .cart-new-icon svg,
.fixed-menu .com-link svg {
  /* color: var(--black); */
  color: var(--white);
}
.fixed-menu .com-link:nth-child(3) svg {
  color: var(--white);
}
.fixed-menu .dropdown-toggle::after {
  display: none;
}
.silder-div-text {
  position: relative;
  z-index: 2;
}
.subsribe-div h6 {
  font-size: 26px;
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
}
.cart-products-div {
  position: relative;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  margin: auto;
}

#myCarousel {
  max-width: 650px;
  margin: 0 auto;
}

#thumbCarousel {
  max-width: 650px;
  margin: 0 auto;
  overflow: hidden;
  background: #eaeaea;
  padding: 10px 0;
}

#thumbCarousel .thumb {
  float: left;
  margin-right: 10px;
  border: 1px solid #ccc;
  background: var(--white);
}

#thumbCarousel .thumb:last-child {
  margin-right: 0;
}

.thumb:hover {
  cursor: pointer;
}

.thumb img {
  opacity: 0.5;
}

.thumb img:hover {
  opacity: 1;
}

.thumb.active img {
  opacity: 1;
  border: 1px solid #1d62b7;
}
.mn-big-pic {
  height: 347px;
}
.mn-big-pic img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.filter-menu-q .comon-filter-bn {
  background: #28292b;
  color: var(--white);
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin: 0 7px;
  text-transform: capitalize;
  border: 0;
  border-radius: 0;
}
/* .filter-menu-q .active .comon-filter-bn {
  background: var(--primarycolor);
  color: var(--white);
} */
.filter-menu-q .comon-filter-bn:hover,
.filter-menu-q .comon-filter-bn:focus,
.filter-menu-q .comon-filter-bn.active {
  background: var(--primarycolor);
  box-shadow: none;
  color: var(--white);
}
.sp-bg-page .page-link {
  color: var(--black);
  background: var(--primarycolor);
  border-color: var(--primarycolor);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.sp-bg-page .page-item.active .page-link,
.sp-bg-page .page-link:hover {
  color: var(--white);
  background: var(--primarycolor);
  border-color: var(--primarycolor);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}
.page-item:last-child .page-link {
  margin-right: 0;
}

/* ******* shop page css********* */
.sp-bg-page {
  /* background: var(--black); */
  background: var(--bgcolor);
  background: var(--white);
  /* background-image: url("./images/bg-wine-shop.jpg");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 40%; */
}

/* new product card style */
.sp-bg-page .produc-div {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  /* box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1); */
}
.sp-bg-page .produc-div .products-box {
  padding: 10px;
}
.sp-bg-page .produc-div .ps-details .cate-text {
  font-size: 14px;
  font-weight: 600;
}
.sp-bg-page .produc-div .ps-details .brand-tite {
  font-size: 14px;
  font-weight: 500;
  color: #858080;
}
.sp-bg-page .produc-div .ps-details .products-titel {
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}
.sp-bg-page .produc-div .ps-details .price-text {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}

/* Old product card style */
.sp-bg-page .comon-items-d1 {
  background: none;
  position: relative;
  /* box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1);
  background: var(--white); */
}
.sp-bg-page .products-img-div,
.sp-bg-page .products-img-div figure {
  width: 100%;
  height: 370px;
  overflow: hidden;
}
.sp-bg-page .products-img-div figure img {
  object-fit: cover;
  width: 100%;
  /* height: 110%; */
  height: 100%;
}
.sp-bg-page .comon-items-d1 {
  width: 100%;
  height: 370px;
  overflow: hidden;
  border-radius: 8px;
  padding: 0;
}
.sp-bg-page .comon-items-d1 .card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding: 0.5rem;
  background: rgba(225, 225, 225, 0.4);
}
.sp-bg-page .comon-items-d1 .card-body .products-titel,
.sp-bg-page .comon-items-d1 .card-body .price-text,
.sp-bg-page .comon-items-d1 .card-body .cate-text {
  /* color: var(--white) !important; */
  color: var(--black) !important;
  text-transform: capitalize;
}
.sp-bg-page .comon-items-d1 .card-body .cate-text {
  font-size: 14px;
  font-weight: 600;
  color: var(--secondarycolor) !important;
}
.sp-bg-page .comon-items-d1 .card-body .brand-tite {
  color: #858080;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  margin: 0.3rem 0 0 0;
}
.sp-bg-page .comon-items-d1 .card-body .products-titel,
.sp-bg-page .comon-items-d1 .card-body .price-text {
  font-size: 14px;
  font-weight: 600;
}
.sp-bg-page .comon-items-d1 .hover-show-bn {
  height: 370px;
  top: -370px;
}
.sp-bg-page .comon-items-d1:hover .hover-show-bn {
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 80%);
}
.sp-bg-page .old-pice {
  color: var(--secondarycolor);
  font-size: 10px;
  font-weight: 600;
}
.sp-bg-page .products-img-div .offer {
  left: 14px;
  top: 9px;
}
.pagination .active > .page-link {
  background: var(--primarycolor) !important;
  color: var(--white) !important;
  border-color: var(--primarycolor) !important ;
}
.pagination .page-link {
  cursor: pointer !important;
}

/* ********shop top Details  css********* */
.shop_top_details h2 {
  text-align: center;
}
.shop_top_details p {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1.5rem;
}
/* ********shop top bar css********* */
.shop_top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shop_top_bar .sort_list {
  width: max-content;
  background-color: transparent;
  /*  background-image: url("./images/icons/arrowicon.png");
  background-size: 15px 14px; */
  border: 0;
  /* color: var(--white); */
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
}
.shop_top_bar .sort_list:focus {
  box-shadow: none;
}
.shop_top_bar .sort_list option {
  color: var(--black);
  background: var(--lightwhite);
}

.shop_top_bar p {
  /* color: var(--white); */
  color: var(--black);
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

/* ********shop filters css********* */
.shop-filers-sec h3 {
  /* color: var(--white); */
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  /* text-transform: capitalize; */
  /* border-bottom: 1px solid var(--lightwhite); */
  border-bottom: 1px solid var(--black);
  padding-bottom: 0.7rem;
  margin-bottom: 1rem;
  font-family: "PlayFair", sans-serif;
}
.shop-filers-sec .filter_note {
  /* color: var(--lightwhite); */
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.shop-filers-sec .brands_list_wraper {
  margin-bottom: 2rem;
}

.shop-filers-sec .show_more_less {
  color: var(--secondarycolor);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  list-style: none;
  text-align: center;
}
.shop-filers-sec .show_close {
  color: rgb(55, 55, 192);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  list-style: none;
  text-align: center;
}
.shop-filers-sec .form-control {
  border-radius: 0;
  border: 0.6px solid var(--primarycolor);
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
  padding: 0.5rem 1rem;
  background: transparent;
}
.shop-filers-sec .form-control:focus {
  box-shadow: none;
  border: 0.6px solid var(--primarycolor);
  background: transparent;
}

.shop-filers-sec .input-group-text {
  border: 1px solid var(--primarycolor);
  color: var(--primarycolor);
  background: transparent;
  border-radius: 0;
  border-left: 0;
}

.shop-filers-sec .brands_list_wraper .brands_list li {
  /* color: var(--lightwhite); */
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shop-filers-sec .brands_list_wraper .brands_list li:hover,
.shop-filers-sec .brands_list_wraper .brands_list li.active {
  color: var(--primarycolor);
}
.shop-filers-sec .subcategory_list_wraper {
  margin-bottom: 2rem;
}

.shop-filers-sec .subcategory_list_wraper .subcategory_list .filter-bn {
  /* color: var(--lightwhite); */
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  /* border: 1px solid var(--lightwhite); */
  border: 1px solid var(--black);
  border-radius: 0;
  margin: 0.5rem;
}
.shop-filers-sec .subcategory_list_wraper .subcategory_list .filter-bn:hover,
.shop-filers-sec .subcategory_list_wraper .subcategory_list .filter-bn.active {
  background: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  color: var(--white);
}

.shop-filers-sec .price_range_wraper .rc-slider-track {
  background-color: var(--primarycolor);
}
.shop-filers-sec .price_range_wraper .rc-slider-handle {
  border: solid 2px var(--primarycolor);
  opacity: 1;
}
.shop-filers-sec .price_range_wraper .rc-slider-handle:focus-visible {
  border-color: var(--primarycolor);
  box-shadow: 0 0 0 3px var(--primarycolor);
}
.shop-filers-sec .price_range_wraper .range-val {
  /* color: var(--lightwhite); */
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-top: 1rem;
}
/* ************shop faqs******************* */
.shop-faq-sec .accordion-button::after {
  content: "\f105" !important;
  background-image: none !important;
  font-family: "FontAwesome";
  display: grid;
  align-content: center;
  justify-content: center;
  background: var(--white);
  color: var(--primarycolor);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  border-radius: 20px;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.shop-faq-sec .accordion-button:not(.collapsed)::after {
  content: "\f106" !important;
  float: right;
  font-family: "FontAwesome";
  background-image: none !important;
  background: var(--primarycolor);
  color: var(--white);
}
.shop-faq-sec .accordion-button {
  padding: 10px 20px;
  font-family: "PlayFair", sans-serif;
  font-size: 22px;
  font-weight: 600;
  border-radius: 18px !important;
}
.shop-faq-sec .accordion-button:not(.collapsed) {
  background-color: var(--white) !important;
  /* border: 1px solid var(--primarycolor) !important; */
  color: var(--black) !important;
  box-shadow: none;
  padding-bottom: 0;
}

.shop-faq-sec .accordion-button:focus {
  box-shadow: none;
}
.shop-faq-sec .accordion-item {
  margin-bottom: 25px;
  border-radius: 0 !important;
  background: var(--white);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  border: 0;
  border: 1px solid var(--primarycolor) !important;
}

.shop-faq-sec .accordion-body p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.shop-faq-sec .accordion-body {
  padding-top: 0;
}

.prod_note {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: var(--black);
}
.comm_prod_note {
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
}
/* **********age Pop Up css******* */
.age_modal .modal-content {
  background: url("https://royal-spirit.b-cdn.net/images/popupbg-171395463632.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.age_modal .age_pop_up_wrape {
  text-align: center;
  padding: 2rem !important;
}
.age_modal .age_pop_up_wrape img {
  margin-bottom: 1rem;
}
.age_modal .age_pop_up_wrape h3 {
  color: var(--black);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.age_modal .age_pop_up_wrape h3 span {
  color: var(--secondarycolor);
}
.age_modal .age_pop_up_wrape p {
  color: var(--black);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.age_modal .age_pop_up_wrape .btn_style1 {
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  color: var(--white);
  margin-right: 0.5rem;
  padding: 0.7rem 2rem;
  text-transform: uppercase;
}

.age_modal .age_pop_up_wrape .btn_style1:hover,
.age_modal .age_pop_up_wrape .btn_style1:focus {
  background-color: var(--white);
  color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.age_modal .age_pop_up_wrape .btn_style2 {
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--black);
  border: 1px solid var(--black);
  color: var(--white);
  padding: 0.7rem 2rem;
  text-transform: uppercase;
}
.age_modal .age_pop_up_wrape .btn_style2:hover,
.age_modal .age_pop_up_wrape .btn_style2:focus {
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
}
.age_modal .age_pop_up_wrape .btn_style3 {
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--white);
  border: 1px solid var(--black);
  color: var(--black);
  padding: 0.7rem 2rem;
  text-transform: uppercase;
}
.age_modal .age_pop_up_wrape .btn_style3:hover,
.age_modal .age_pop_up_wrape .btn_style3:focus {
  background-color: var(--black);
  color: var(--white);
}
.age_modal .age_pop_up_wrape .pop_up_breaker {
  margin: 1rem 0;
  font-size: 14px;
  color: var(--black);
  font-weight: 600;
  position: relative;
}
.age_modal .age_pop_up_wrape .pop_up_breaker::before {
  content: "";
  position: absolute;
  background-color: var(--black);
  width: 45%;
  height: 1px;
  top: 50%;
  left: 0;
}
.EyeContainer {
  cursor: pointer;
  font-size: 20px;
}
.EyeContainer svg {
  color: rgb(6, 117, 6) !important;
}
.age_modal .age_pop_up_wrape .pop_up_breaker::after {
  content: "";
  position: absolute;
  background-color: var(--black);
  width: 45%;
  height: 1px;
  top: 50%;
  right: 0;
}
.age_modal .age_pop_up_wrape .btn_style4 {
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  color: var(--black);
  padding: 0.7rem 2rem;
  text-transform: uppercase;
}
.age_modal .age_pop_up_wrape .btn_style4:hover,
.age_modal .age_pop_up_wrape .btn_style4:focus {
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--bgcolor);
}

/* **********login pop up********** */
.LoginStoreLogo {
  width: 150px;
  transform: scale(1);
  transition: all 0.5s;
}
.LoginStoreLogo:hover {
  transform: scale(1.1);
  transition: all 0.5s;
}
.qrLogoImage {
  width: 140px;
}
.profile_bg_img {
  position: relative;
  min-height: 750px;
}

.DownloadWrapper {
  position: absolute;
  top: 25%;
  left: 30px;
}
.QrWrapper {
  position: absolute;
  top: 23%;
  right: 30px;
}
.profile_modal .modal-content {
  border: 0;
  border-radius: 0;
}
.profile_modal .profile_pop_up_wrape {
  padding: 0;
}
.profile_modal .profile_pop_up_wrape .profile_bg_img {
  background: url("./images/bgimages/LOGINBg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.profile_modal .profile_pop_up_wrape .profile_content {
  padding: 1rem 2rem 2rem 2rem;
}
.profile_modal .profile_pop_up_wrape .profile_content .closeIcon {
  color: var(--black);
  cursor: pointer;
}
.profile_modal .profile_pop_up_wrape .profile_content img {
  margin-bottom: 1rem;
}
.profile_modal .profile_pop_up_wrape .profile_content .form-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
.profile_modal .profile_pop_up_wrape .profile_content .form-control {
  border-radius: 0;
  border: 0.6px solid var(--primarycolor);
  background: var(--white);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}
.profile_modal .profile_pop_up_wrape .profile_content .form-control:focus {
  box-shadow: none;
  border: 0.6px solid var(--primarycolor);
}
.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .react-tel-input
  .flag-dropdown {
  border: 0.6px solid var(--primarycolor);
  background: var(--white);
}
.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .react-tel-input
  .form-control {
  width: 100%;
  padding-left: 48px !important;
  height: 100%;
}

.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .react-tel-input
  .country-list
  .search-box {
  border: 0.6px solid var(--primarycolor);
  border-radius: 0;
  color: var(--black);
  margin-left: 0;
}

.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .react-tel-input
  .selected-flag {
  border-radius: 0;
}

.profile_modal .profile_pop_up_wrape .profile_content .input-group-text {
  border: 1px solid var(--primarycolor);
  border-radius: 0;
  color: var(--black);
  background: var(--white);
}
.profile_modal .profile_pop_up_wrape .profile_content .form-check {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}
.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .form-check
  .form-check-input:checked {
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
}
.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .form-check
  .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 188, 91, 0.25);
}
.profile_modal .profile_pop_up_wrape .profile_content .forget_pass {
  font-size: 16px;
  font-weight: 400;
  color: var(--primarycolor);
  cursor: pointer;
}
.profile_modal .profile_pop_up_wrape .profile_content .forget_pass_title {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
.profile_modal .profile_pop_up_wrape .profile_content .sign_up_note {
  font-size: 12px;
  font-weight: 600;
  color: var(--black);
}

.profile_modal .profile_pop_up_wrape .profile_content .btn_style1 {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  color: var(--white);
  margin-right: 0.5rem;
  padding: 0.7rem 2rem;
  text-transform: capitalize;
  width: 100%;
}
.profile_modal .profile_pop_up_wrape .profile_content .btn_style1:hover,
.profile_modal .profile_pop_up_wrape .profile_content .btn_style1:focus {
  background-color: var(--white);
  color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.profile_modal .profile_pop_up_wrape .profile_content .Back_btn {
  max-width: max-content;
  padding: 0.4rem 0.5rem 0.4rem;
  font-size: 16px;
}

.profile_modal .profile_pop_up_wrape .profile_content .btn_style2 {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--black);
  border: 1px solid var(--black);
  color: var(--white);
  padding: 0.7rem 2rem;
  text-transform: capitalize;
  width: 100%;
}
.profile_modal .profile_pop_up_wrape .profile_content .btn_style2:hover,
.profile_modal .profile_pop_up_wrape .profile_content .btn_style2:focus {
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
}

.profile_modal .profile_pop_up_wrape .profile_content .pop_up_breaker {
  margin: 1rem 0;
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
  text-align: center;
}

.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .my-facebook-button-class {
  padding: 0.7rem 1rem !important;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  border-radius: 0 !important;
  margin-bottom: 1rem !important;
  width: 100% !important;
  background-color: #4c69ba;
  border-color: #4c69ba;
  color: var(--white);
}
.profile_modal
  .profile_pop_up_wrape
  .profile_content
  .my-facebook-button-class
  .fa {
  margin-right: 0.5rem;
}

.profile_modal .profile_pop_up_wrape .profile_content .google-btn-class {
  padding: 0.7rem 2rem !important;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  border-radius: 0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

#timer p {
  font-size: 14px;
  color: rgb(120, 120, 120);
}
#timer p span {
  cursor: pointer;
  color: rgb(54, 54, 54);
}
#timer p span:hover {
  text-decoration: underline;
}

/* ********account section****** */
.dashboard-sec .btn_logout {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--white);
  border: 1px solid var(--primarycolor);
  color: var(--black);
  margin-top: 1.5rem;
  padding: 0.7rem 2rem;
  text-transform: capitalize;
  width: 100%;
}
.dashboard-sec .btn_logout:hover,
.dashboard-sec .btn_logout:focus {
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.dashboard-sec .dashboard_detail {
  border-radius: 0;
  border: 1px solid var(--black);
  padding: 2rem 1rem;
  cursor: pointer;
}
.dashboard-sec .dashboard_detail:hover,
.dashboard-sec .dashboard_detail.active {
  background: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.dashboard-sec .dashboard_detail .userIocn {
  width: 70px;
  height: 70px;
  display: grid;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 1px 22px 0 rgb(0 0 0 / 12%);
  padding: 11px;
  background: var(--white);
  margin: auto;
}
.dashboard-sec .dashboard_detail .userIocn img {
  margin: auto;
  width: 87%;
  transform: scale(1);
  transition: all 0.5s;
}
.dashboard-sec h2 {
  color: var(--black);
  margin-bottom: 2rem;
}
.dashboard-sec .dashboard_detail h5 {
  color: var(--black);
  font-family: "Playfair", sans-serif;
  font-size: 26px;
}
.dashboard-sec .dashboard_detail:hover h5,
.dashboard-sec .dashboard_detail:active h5 {
  color: var(--white);
}
/* ********profile section****** */
.profile-sec .profile_detail {
  border-radius: 0;
  border: 1px solid var(--primarycolor);
  padding: 2rem 1rem;
  cursor: pointer;
  position: relative;
}
.profile-sec .profile_detail img {
  border-radius: 100px;
  width: 80px;
  height: 80px;
}
.profile-sec .profile_detail .edit_icon_style {
  position: absolute;
  top: 5%;
  left: 5%;
  color: var(--primarycolor);
}

.profile-sec .profile_detail:hover {
  background: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.profile-sec .profile_detail:hover .edit_icon_style {
  color: var(--white);
}

.profile-sec h2 {
  color: var(--primarycolor);
  margin-bottom: 2rem;
}
.profile-sec img {
  width: 85px;
}
.profile-sec .form-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
.profile-sec .form-control {
  border-radius: 0;
  border: 0.6px solid var(--primarycolor);
  background: var(--white);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}
.profile-sec .form-control:focus {
  box-shadow: none;
  border: 0.6px solid var(--primarycolor);
}

.profile-sec .btn_style {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--white);
  border: 1px solid var(--primarycolor);
  color: var(--black);
  margin-right: 0.5rem;
  padding: 0.7rem 2rem;
  text-transform: capitalize;
  width: 100%;
}
.profile-sec .btn_style:hover,
.profile-sec .btn_style:focus {
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}

/* ********address section****** */
.address-sec .address_detail {
  border-radius: 0;
  border: 1px solid var(--black);
  padding: 2rem 1rem;
  cursor: pointer;
  height: 100%;
}
.address-sec .address_info {
  height: 100%;
}

.address-sec .address_detail:hover,
.address-sec .address_detail.active {
  background: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.address-sec .address_detail .userIocn {
  width: 70px;
  height: 70px;
  display: grid;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 1px 22px 0 rgb(0 0 0 / 12%);
  padding: 11px;
  background: var(--white);
  margin: auto;
}
.address-sec .address_detail .userIocn img {
  margin: auto;
  width: 87%;
  transform: scale(1);
  transition: all 0.5s;
}
.userIocn_img {
  margin: auto;
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: top;
  transform: scale(1);
  transition: all 0.5s;
  border-radius: 50%;
}

.headerProfile_img {
  margin: auto;
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: top;
  transform: scale(1);
  transition: all 0.5s;
  border-radius: 50%;
}
.address-sec h2 {
  color: var(--black);
  margin-bottom: 2rem;
}
.address-sec .address_detail h5 {
  color: var(--black);
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-size: 26px;
}
.address-sec .address_info {
  border-radius: 0;
  border: 1px solid var(--black);
  padding: 1rem;
}

.address-sec .address_info h3,
.address-sec .address_info p {
  font-size: 16px;
  font-weight: 400;
}

.address-sec .address_info .address_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-sec .address_info .address_badge {
  padding: 0.5rem 1rem;
  border-radius: 0;
  background: var(--white);
  border: 1px solid var(--primarycolor);
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
}
.address-sec .address_info .address_badge:hover,
.address-sec .address_info .address_badge.active {
  background: var(--primarycolor);
  color: var(--white);
}

.address-sec .address_info .dots_wrape {
  position: relative;
}

.address-sec .address_info .dots_wrape .dotsStyle {
  color: var(--black);
  cursor: pointer;
}
.address-sec .address_info .dots_wrape .dotsStyle.active {
  color: var(--primarycolor);
}

.address-sec .address_info .dots_wrape .menu-items {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--white);
  list-style: none;
  padding: 0;
  margin: 0;
  width: 150px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.address-sec .address_info .dots_wrape .menu-items.active {
  opacity: 1;
  transform: translateY(0);
}
.address-sec .address_info .dots_wrape .menu-items li {
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  border-bottom: 1px solid var(--bgcolor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.address-sec .address_info .dots_wrape .menu-items li:last-child {
  border-bottom: 0;
}
.address-sec .address_info .dots_wrape .menu-items li:hover {
  background: var(--primarycolor);
}

/****** add address form section **********/
.add_address_modal .modal-content {
  border: 0;
  border-radius: 0;
  background: url("https://royal-spirit.b-cdn.net/images/popupbg-171395463632.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.add_address_modal .add_addres_pop_up_wrape {
  padding: 0;
}
.add_address_modal .add_addres_pop_up_wrape .add_address_content {
  padding: 1rem 2rem 2rem 2rem;
}
.add_address_modal .add_addres_pop_up_wrape .closeIcon {
  color: var(--black);
  cursor: pointer;
}
.add_address_modal .add_addres_pop_up_wrape .add_address_content h2 {
  color: var(--black);
  margin-bottom: 1rem;
}
.add_address_modal .add_addres_pop_up_wrape .add_address_content .form-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
.add_address_modal .add_addres_pop_up_wrape .add_address_content .form-control {
  border-radius: 0;
  border: 0.6px solid var(--primarycolor);
  background: var(--white);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}
.add_address_modal
  .add_addres_pop_up_wrape
  .add_address_content
  .form-control:focus {
  box-shadow: none;
  border: 0.6px solid var(--primarycolor);
}
.add_address_modal .add_addres_pop_up_wrape .add_address_content .form-check {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}
.add_address_modal
  .add_addres_pop_up_wrape
  .add_address_content
  .form-check
  .form-check-input:checked {
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
}
.add_address_modal
  .add_addres_pop_up_wrape
  .add_address_content
  .form-check
  .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 188, 91, 0.25);
}

.add_address_modal .add_addres_pop_up_wrape .add_address_content .btn_style {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--white);
  border: 1px solid var(--primarycolor);
  color: var(--black);
  margin-right: 0.5rem;
  padding: 0.7rem 3rem;
  text-transform: capitalize;
}
.add_address_modal
  .add_addres_pop_up_wrape
  .add_address_content
  .btn_style:hover,
.add_address_modal
  .add_addres_pop_up_wrape
  .add_address_content
  .btn_style:focus {
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}

/* ******Orders History section css**** */
.order_wrape h2 {
  color: var(--black);
  margin-bottom: 1rem;
}
.order_wrape .order_item_wrape .order_item {
  padding: 1rem;
  border: 1px solid var(--black);
  border-radius: 0;
  margin-bottom: 1rem;
}
.order_wrape .order_item_wrape .order_item:last-child {
  margin-bottom: 0;
}
.order_wrape .order_item_wrape .order_item .img_wrape {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.order_wrape .order_item_wrape .order_item .img_wrape img {
  width: 100px;
  margin-bottom: 0.5rem;
}
.order_wrape .order_item_wrape .order_item .img_wrape .btn_wrape button {
  display: block;
}
.order_wrape .order_item_wrape .order_item .img_wrape .btn_wrape .btn_submit {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--white);
  border: 1px solid var(--primarycolor);
  color: var(--black);
  margin-right: 0.5rem;
  padding: 0.5rem 2rem;
  text-transform: capitalize;
}
.order_wrape
  .order_item_wrape
  .order_item
  .img_wrape
  .btn_wrape
  .btn_submit:hover,
.order_wrape
  .order_item_wrape
  .order_item
  .img_wrape
  .btn_wrape
  .btn_submit:focus {
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.order_wrape .order_item_wrape .order_item .img_wrape .btn_wrape .btn_order {
  border-radius: 0;
  background: transparent;
  border: 0;
  color: var(--black);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  padding: 0 2rem;
  margin-top: 1rem;
  width: 100%;
}
.order_wrape
  .order_item_wrape
  .order_item
  .img_wrape
  .btn_wrape
  .btn_order:focus {
  outline: 0;
  box-shadow: none;
}
.order_wrape
  .order_item_wrape
  .order_item
  .img_wrape
  .btn_wrape
  .btn_order:hover {
  color: var(--primarycolor);
}

.order_wrape .order_item_wrape .order_item .order_detail {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.order_wrape .order_item_wrape .order_item .order_detail .order_number {
  font-size: 16px;
  font-weight: 500;
  border-right: 1px solid var(--black);
  padding: 0 1rem;
}

.order_wrape
  .order_item_wrape
  .order_item
  .order_detail
  .order_number:last-child {
  border-right: 0;
}
.order_wrape .order_item_wrape .order_item .order_detail .order_number span {
  font-size: 15px;
  font-weight: 400;
}
/* *****Order Details ******** */

.view_order_modal .modal-content {
  border: 0;
  border-radius: 0;
  background: url("https://royal-spirit.b-cdn.net/images/popupbg-171395463632.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.view_order_modal .view_order_pop_up_wrape {
  padding: 0;
}
.view_order_modal .view_order_pop_up_wrape .closeIcon {
  color: var(--black);
  cursor: pointer;
}
.view_order_modal .view_order_pop_up_wrape .view_order_content {
  padding: 1rem 2rem 2rem 2rem;
}
.view_order_modal .view_order_pop_up_wrape .view_order_content h2 {
  color: var(--black);
  margin-bottom: 1rem;
}
.view_order_modal .view_order_pop_up_wrape .view_order_content h3 {
  color: var(--black);
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 26px;
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
}
.view_order_modal .view_order_pop_up_wrape .view_order_content h5 {
  color: var(--black);
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 16px;
}
.view_order_modal .view_order_pop_up_wrape .view_order_content p {
  color: var(--black);
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-size: 16px;
}
.view_order_modal .view_order_pop_up_wrape .view_order_content figure {
  height: 150px;
  margin-bottom: 0.5rem;
  overflow: hidden;
}
.view_order_modal .view_order_pop_up_wrape .view_order_content figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* ********change Password section css***** */
.change_pass_modal .modal-content {
  border: 0;
  border-radius: 0;
  background: url("https://royal-spirit.b-cdn.net/images/popupbg-171395463632.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.change_pass_modal .change_pass_pop_up_wrape {
  padding: 0;
}
.change_pass_modal .change_pass_pop_up_wrape .closeIcon {
  color: var(--black);
  cursor: pointer;
}
.change_pass_modal .change_pass_pop_up_wrape .change_pass_content {
  padding: 1rem 2rem 2rem 2rem;
}
.change_pass_modal .change_pass_pop_up_wrape .change_pass_content h2 {
  color: var(--black);
  margin-bottom: 1rem;
}

.change_pass_modal .change_pass_pop_up_wrape .change_pass_content .form-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
.change_pass_modal
  .change_pass_pop_up_wrape
  .change_pass_content
  .form-control {
  border-radius: 0;
  border: 0.6px solid var(--primarycolor);
  background: var(--white);
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0.5rem 1rem;
}
.change_pass_modal
  .change_pass_pop_up_wrape
  .change_pass_content
  .form-control:focus {
  box-shadow: none;
  border: 0.6px solid var(--primarycolor);
}

.change_pass_modal .change_pass_pop_up_wrape .change_pass_content .btn_style {
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--white);
  border: 1px solid var(--primarycolor);
  color: var(--black);
  margin-right: 0.5rem;
  padding: 0.5rem 2rem;
  text-transform: capitalize;
}
.change_pass_modal
  .change_pass_pop_up_wrape
  .change_pass_content
  .btn_style:hover,
.change_pass_modal
  .change_pass_pop_up_wrape
  .change_pass_content
  .btn_style:focus {
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}

/* *******Track Order Sections Css */
.track_order_modal .modal-content {
  border: 0;
  border-radius: 0;
  background: url("https://royal-spirit.b-cdn.net/images/popupbg-171395463632.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.track_order_modal .track_order_pop_up_wrape {
  padding: 0;
}
.track_order_modal .track_order_pop_up_wrape .closeIcon {
  color: var(--black);
  cursor: pointer;
}
.track_order_modal .track_order_pop_up_wrape .track_order_content {
  padding: 1rem 2rem 2rem 2rem;
}
.track_order_modal .track_order_pop_up_wrape .track_order_content h2 {
  color: var(--black);
  margin-bottom: 1.5rem;
}
.track_order_modal .track_order_pop_up_wrape .track_order_content h2 span {
  color: var(--primarycolor);
  font-size: 30px;
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking {
  border: 1px solid var(--primarycolor);
  padding: 2rem;
  border-radius: 0;
}
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .order_placed_note
  p {
  text-align: center;
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1.5rem;
}
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step {
  display: block;
  width: 100%;
  text-align: center;
}
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step
  .step-icon-wrap {
  position: relative;
  height: 80px;
  display: block;
  width: 100%;
  text-align: center;
  z-index: 1;
}
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step
  .step-icon-wrap::before,
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step
  .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #ddd;
  content: "";
  z-index: -1;
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step
  .step-icon-wrap::before {
  left: 0;
}
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step
  .step-icon-wrap::after {
  right: 0;
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step:first-child
  .step-icon-wrap::before {
  display: none;
}
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step:last-child
  .step-icon-wrap::after {
  display: none;
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step
  .step-icon-wrap
  .step-icon {
  display: inline-block;
  padding: 1.5rem;
  border: 0.5px solid #7d879c56;
  border-radius: 50%;
  background: #ddd;
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step
  .step-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  margin: 1rem 0 0 0;
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step.completed
  .step-icon-wrap::after,
.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step.completed
  .step-icon-wrap::before {
  background-color: var(--primarycolor);
}

.track_order_modal
  .track_order_pop_up_wrape
  .track_order_content
  .order_tracking
  .steps
  .step.completed
  .step-icon-wrap
  .step-icon {
  background: var(--primarycolor);
  color: var(--white);
  border-color: var(--primarycolor);
}

/* ******error page css******* */

.error-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-wrapper .txt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px #eee;
  background-color: var(--white);
  padding: 2rem 1rem;
  z-index: 99;
}
.trackWrapper {
  position: relative;
}
.TrackList {
  animation-name: my-animation;
  animation-duration: 0.2s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  position: absolute;
  top: 100%;
  background-color: white;
  height: 300px;
  border: 1px solid var(--primarycolor);
  overflow: scroll;
}
.TrackList p {
  cursor: pointer;
  padding: 10px 20px;
  margin-bottom: 0;
}
.TrackList p:hover {
  cursor: pointer;
  background-color: var(--primarycolor);
  color: white;
}
@keyframes my-animation {
  from {
    height: 0;
  }
  to {
    height: 300px;
  }
}
.error-wrapper .txt-wrapper .status-code {
  font-weight: 600;
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-size: 5.2rem;
  line-height: 57px;
  color: var(--black);
}
.error-wrapper .txt-wrapper .subtext {
  font-size: 3rem;
  line-height: 46px;
  font-family: sans-serif;
  font-weight: 200;
  color: var(--black);
}
.error-wrapper .txt-wrapper .description {
  font-size: 1.2rem;
  line-height: 26px;
  color: var(--black);
  font-family: sans-serif;
  font-weight: 200;
  width: 75%;
  text-align: center;
}
.error-wrapper .txt-wrapper button {
  border-radius: 0;
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
  color: var(--white);
  text-transform: capitalize;
  font-weight: 500;
  margin: auto;
  padding: 0.7rem 4rem !important;
  outline: 0;
  box-shadow: 0;
}
.error-wrapper .txt-wrapper button:focus {
  outline: 0;
  box-shadow: none;
  border-color: var(--primarycolor);
}
.error-wrapper .txt-wrapper button:hover {
  color: var(--primarycolor);
  background: var(--white);
}

/* ******Gifts Offer Section */
.gifts_offer_sec .main-title {
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 1.5rem;
}
.gifts_offer_sec .detail {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
}
.gifts_offer_sec .comon-offer h5 {
  text-align: center;
}

/* ******Intro common Section */
.intro_common_sec h2 {
  font-size: 35px;
  margin-bottom: 2rem;
}
.intro_common_sec h3 {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1rem;
  /* font-family: "Lobster Two", cursive; */
  /* font-family: Barlow Condensed, sans-serif; */
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Montserrat", sans-serif;
}
.intro_common_sec p,
.intro_common_sec ul {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 1.5rem;
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
}
.intro_common_sec p a,
.intro_common_sec ul li a {
  text-decoration: none;
  color: var(--primarycolor);
}

/* **** download app section */

.download_app_sec {
  /* background: var(--black); */
  background-image: url("./images/bgimages/contact_img.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.download_app_sec3 {
  /* background: var(--black); */
  /* background-image: url("./images/bgimages/BOTTOM.webp"); */
  background-image: url("./images/bgimages/download-app.webp");
  min-height: 400px;
}
.download_app_sec .mobileBanner {
  height: 350px;
}
.download_app_sec h2 {
  font-size: 40px !important;
  color: var(--white);
}
.download_app_sec3 h2,
.download_app_sec3 p {
  color: var(--white) !important;
}
.download_app_sec p {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  color: var(--white);
}
.download_app_sec .form-control {
  background: var(--formbgcolor);
  color: var(--black);
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
}

.download_app_sec .form-control:focus {
  box-shadow: none;
  border: 1px solid var(--primarycolor);
}
.download_app_sec .download-btn {
  /* background: var(--primarycolor); */
  /* border: 1px solid var(--primarycolor); */
  background: var(--black);
  border: 1px solid var(--black);

  color: var(--white);
  /* font-family: "Quicksand", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.7rem 4rem;
  border-radius: 0;
}

.download_app_sec .download-btn:focus,
.download_app_sec .download-btn:hover {
  background: var(--white);
  color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}

.download_app_sec .download_app_icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 76%;
  left: 10%;
}
.download_app_sec .download_app_icons img {
  width: 150px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.download_app_sec .download_app_icons a {
  text-decoration: none;
  color: var(--black);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.download_app_sec .download_app_icons a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.ViewMOreBtn {
  color: var(--white) !important;
  background-color: var(--primarycolor) !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  font-size: 16px !important;
  transition: all 0.5s;
  border-radius: 0;
  padding: 0.5rem 2rem !important;
}
.ViewMOreBtnDone {
  opacity: 0.3;
}
.primary_btn {
  color: var(--white) !important;
  background-color: var(--primarycolor) !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
  transition: all 0.5s;
  border-radius: 0;
  padding: 0.5rem 1rem !important;
}
/* ****Payment sec */
.payment_sec h2 {
  margin-bottom: 1rem;
}
.payment_sec .apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 0.5rem 1rem;
  --apple-pay-button-box-sizing: border-box;
}

.payment_sec .form-check {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}
.payment_sec .form-check .form-check-input:checked {
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
}
.payment_sec .form-check .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 188, 91, 0.25);
}

/* react toastify close btn css */
.Toastify__close-button {
  display: none !important;
}
.Toastify__toast-body {
  font-size: 15px;
}

.order_note {
  background: #f2ce1154;
  padding: 0.7rem 1rem;
  margin-bottom: 2rem;
  width: fit-content;
}
.order_note p {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}

/************* checkout page************* */

.payment_card_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bgcolor);
  padding: 0.6rem 0;
}
.payment_card_style label {
  margin-bottom: 0;
}
.addressDetail {
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.AddressStyle {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--bgcolor);
  padding: 0.6rem 0;
}
.AddressStyle label {
  margin-bottom: 0;
}

.store_location {
  margin: 1rem 0;
  font-size: 15px;
  font-weight: 400;
}
.store_location a {
  text-decoration: none;
  color: var(--secondarycolor);
}
.saveBtnAnim {
  animation: scaleUp 0.5s;
  animation-delay: 1.5s;
}
@keyframes scaleUp {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
    scale: 1.2;
    opacity: 0.7;
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
    opacity: 1;
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
    scale: 1.4;
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
    scale: 1;
  }
}
/******** Search Bar ******/

.searchbar-icon {
  position: relative;
  background-color: transparent;
}
.mgl2 {
  margin-left: 10px;
}
.searchbar-icon .search-content {
  /* position: absolute; */
  z-index: 1;
  /* top: 160%; */
  /* top: 0; */
  /* right: 0; */
  /* min-width: 550px; */
  width: 100%;
  padding: 0;
  transition: all 0.5s ease 0s;
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
  animation: fadeScale 0.5s;
  border-radius: 0;
  background-color: transparent;
}

.searchbar-icon .search-content form {
  position: relative;
  text-align: left;
}
.mobileSearch {
  display: none;
}
.searchbar-icon .search-content form input {
  width: 100%;
  padding: 5px 72px 5px 15px;
  transition: all 0.4s ease 0s;
  color: var(--primarycolor);
  border: 1px solid #d3d3d3;
  outline: medium none;
  background: rgba(255, 255, 255, 0.9);
}
.searchbar-icon .search-content form input:focus {
  border: 1px solid var(--primarycolor);
}

.searchbar-icon .search-content .serach_suggestions {
  position: absolute;
  top: 35px;
  overflow-y: scroll;
  background-color: white;
  z-index: 555;
  max-height: 60vh;
}
.searchbar-icon .search-content form p {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.searchbar-icon .search-content form p small {
  color: #a09f9f;
}

.searchbar-icon .search-content form p a {
  color: var(--black) !important;
  text-decoration: none;
  margin: 0 !important;
}

.searchbar-icon .search-content form p a:hover {
  color: var(--primarycolor) !important;
}

.searchbar-icon .search-content form h3 {
  font-size: 22px;
  color: var(--primarycolor);
  margin-bottom: 1rem;
  font-family: "PlayFair", sans-serif;
}
.home-banner {
  width: 100%;
  min-height: 600px;
}
.offerHOme {
  width: 100%;
  min-height: 550px;
}
.searchbar-icon .search-content .button-search {
  font-size: 18px;
  line-height: 1.4;
  position: absolute;
  top: 0;
  right: 0;
  height: fit-content;
  padding: 5px 20px 2px;
  transition: all 0.4s ease 0s;
  text-transform: uppercase;
  color: var(--white);
  border: none;
  border-color: transparent;
  background: transparent;
}
.searchbar-icon .search-content .button-search svg {
  color: var(--primarycolor);
  font-size: 18px;
}
.border1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.searchbar-icon .search-content form .button-search:hover {
  color: var(--black);
}
.CloseIconSearch {
  color: var(--primarycolor) !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeScale {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
  }
}

.copyCodeBtn {
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  border: none;
  color: var(--white);
  padding: 0;
  text-transform: capitalize;
  margin-top: 0.5rem;
}
.copyCodeBtn:hover,
.copyCodeBtn:focus {
  color: var(--primarycolor);
}

/********* Pagination CSS *************/

.pagination_style {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination_style ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.pagination_style ul li {
  color: var(--white);
}
.pagination_style ul li a {
  background: var(--primarycolor);
  color: var(--white);
  width: 35px;
  height: 35px;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.pagination_style ul .selected a {
  border: 1px solid var(--primarycolor);
  color: var(--primarycolor);
  background: transparent;
}

.pagination_style ul .previous a,
.pagination_style ul .next a {
  text-decoration: none;
  color: var(--white);
}

.pagination_style ul .disabled a {
  cursor: not-allowed;
}

/**** News Page css ******/

.news_sec_area .comon-news-part {
  background: transparent;
}
.news_sec_area .dtails-idv-text {
  padding: 0;
}
.news_sec_area .dtails-idv-text h5 {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  white-space: inherit;
  margin: 1rem 0 1.5rem 0;
}
.news_sec_area .comon-pic-news {
  height: 100%;
}
.news_sec_area .comon-pic-news figure {
  height: 400px;
  margin-bottom: 0;
}
.news_sec_area .news_logos_sec {
  background-color: var(--bgcolor);
  padding: 0.5rem;
}
.news_sec_area .news_logos_sec img {
  cursor: pointer;
}

.image-gallery .image-gallery-image {
  height: 58vh !important;
}
figure {
  margin: 0;
}
/*! thumbnail */
.image-gallery .image-gallery-thumbnail {
  width: 60px !important;
}
.image-gallery .image-gallery-thumbnail-image {
  width: 60px !important;
  height: 10vh !important;
}
.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}
.Mgt {
  height: 120px;
}
.prodctScrollPadding {
  scroll-margin: 120px;
}
.products-details-sec {
  scroll-margin: 120px;
}
.topscrollPds {
  scroll-margin: 120px;
}
.topscrollPdsSaveBtn {
  scroll-margin: 180px;
}
/**** News Inner Page css ******/

.news_detail_sec .news_details h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}
.news_detail_sec .news_details p {
  font-size: 16px;
  margin-bottom: 2rem;
}
.news_detail_sec .news_details p h3 {
  font-size: 26px;
  margin-bottom: 0.5rem;
  /* font-family: "Lobster Two", cursive; */
  font-family: "Playfair", sans-serif;
  font-weight: 600;
}
.news_detail_sec .news_details figure {
  overflow: hidden;
  height: 550px;
}
.news_detail_sec .news_details figure .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news_detail_sec .news_list {
  margin-top: 3rem;
}
.news_detail_sec .news_list .news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bgcolor);
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.news_detail_sec .news_list .news_item:hover span,
.news_detail_sec .news_list .news_item:focus span {
  color: var(--secondarycolor);
}

.news_detail_sec .news_list .news_item span {
  font-size: 16px;
  /* text-transform: capitalize; */
}
.news_detail_sec .news_list .news_item img {
  width: 200px;
}
/************ Floating Icon css *******************/
.floating_icon_wrape {
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  z-index: 9999999;
}
.floating_icon_wrape .contact-pannel-btn {
  background: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
}
.floating_icon_wrape .contact-pannel-btn figure {
  width: 55px;
  height: 55px;
  overflow: hidden;
  margin: 0;
}
.floating_icon_wrape .contact-pannel-btn figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.SuggestWrapper,
.InputWrapper {
  position: relative;
}
.suggestWrapperList {
  position: absolute;
  left: 0;
  top: 100%;
  max-height: 50px;
}
.SuggestionList {
  padding: 10px;
  background-color: white;
  cursor: pointer;
}
.SuggestionList:hover {
  background-color: var(--primarycolor);
  color: white;
}
.pointerCursor input {
  cursor: pointer;
}
.featured-sec {
  /* margin-bottom: -40px;
  margin-top: 10px; */
}
.paymentTypeImg {
  height: 30px;
  width: auto;
}
.fileUPloadmsg {
  color: green;
}
.fileUPloadErrorMsg {
  color: tomato;
}
.form_error_msg {
  font-size: 14px;
  margin: 0;
}
/************ Mobile Responsiveness css *******************/

@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .banner-part {
    height: 720px;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .searchbar-icon .search-content {
    position: absolute;
    z-index: 1;
    top: 130%;
    right: 0;
    width: 350px;
    padding: 15px;
    transition: all 0.5s ease 0s;
    background: transparent none repeat scroll 0 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    animation: fadeScale 0.5s;
    border-radius: 0;
    background-color: #ffffff;
  }
}

@media screen and (max-width: 991px) {
  .home-banner {
    width: 100%;
    min-height: 300px;
  }
  .logo-main {
    display: none;
  }
  .logo-mobile {
    display: block;
  }

  /* .com-link:last-child {
    width: 35px;
    height: 35px;
  } */

  /* .login-top {
      display: none !important;
    } */
  /* body */
  .height-offer {
    height: 200px;
  }
  .comon-offer h5 {
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
  /* banner */
  .banner-part {
    background-size: contain;
    height: 450px;
    margin-top: 100px;
  }
  .slider h2,
  .slider-content p {
    text-shadow: var(--black) 1px 2px 8px;
  }
  .comon-offer h5 span {
    font-size: 22px;
  }
  .cate-list li a {
    font-size: 14px;
  }
  .subpage-banner {
    height: 330px;
    margin-top: 110px;
  }
  .cart-products-titel,
  .pro-sec {
    text-align: center;
  }
  .cart-products-titel {
    margin: auto;
  }
  .pro-sec .form-group {
    width: 46%;
    margin: 10px auto;
  }
  .cart-dropdown-ne {
    right: -60px !important;
    top: 170% !important;
  }
  .copyright-wraper {
    flex-direction: column;
    justify-content: center;
  }
  .copyright-wraper .copyright-text {
    margin-bottom: 1rem;
  }
  .pagination_style ul {
    list-style: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.5rem;
    background: var(--primarycolor);
  }

  .pagination_style ul li {
    background: transparent;
    color: var(--white);
    width: auto;
    height: auto;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }
  .pagination_style ul .selected {
    border: none;
    color: var(--black);
    background: transparent;
  }
  .banner-part .banner-content {
    width: 60%;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  .searchbar-icon .search-content {
    width: 60% !important;
    min-width: 260px;
    padding: 0;
    margin: auto;
    animation: fadeScale 0.5s;
    background-color: #000000;
  }
  .Desk_viewSearch {
    display: none;
  }
  .mobileSearch {
    display: block;
  }
}
@media screen and (min-width: 576px) and (max-width: 991px) {
  .download_app_sec3 {
    background: url("./images/bgimages/download-app-mobile.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
  }

  .download_app_sec .download_app_icons {
    top: 70%;
    left: 9%;
  }

  .download_app_sec .download_app_icons img {
    width: 150px;
    transition: 0.3s;
  }

  .hed-n {
    background: var(--black);
  }

  .banner-part .banner-content h2 {
    font-size: 45px !important;
  }
  .sub-page-content h1 {
    font-size: 35px !important;
  }
  .sub-page-content ul li {
    font-size: 15px;
  }
  .searchbar-icon .search-content {
    /* min-width: 380px; */
    width: 100%;
  }
  .com-link svg {
    color: var(--white) !important;
  }
  .comon-about {
    margin-bottom: 1rem;
  }
  .comon-about h2 {
    font-size: 28px !important;
  }
  .comon-about h5 {
    font-size: 18px;
  }
  .profile_bg_img {
    display: none;
  }
  h2,
  .track_order_modal .track_order_pop_up_wrape .track_order_content h2,
  .change_pass_modal .change_pass_pop_up_wrape .change_pass_content h2,
  .profile-sec h2,
  .order_wrape h2,
  .dashboard-sec h2,
  .address-sec h2,
  .add_address_modal .add_addres_pop_up_wrape .add_address_content h2 {
    line-height: normal !important;
    font-size: 36px !important;
  }
}
@media screen and (max-width: 765px) {
  .comon-offer h5 {
    left: 25%;
  }
}
@media screen and (max-width: 575px) {
  .home-banner {
    width: 100%;
    min-height: 650px;
  }
  .offerHOme {
    width: 100%;
    min-height: 700px;
  }
  .download_app_sec3 {
    background: url("./images/bgimages/download-app-mobile.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
  }

  .download_app_sec .download_app_icons {
    top: 68%;
    left: 8%;
  }

  .download_app_sec .download_app_icons img {
    width: 100px;
    transition: 0.3s;
  }

  .profile_bg_img {
    display: none;
  }
  .searchbar-icon .search-content {
    width: 80% !important;
  }
  .wishlist_wrape,
  .cart-btn {
    justify-content: center !important;
    gap: 5px;
  }
  .floating_icon_wrape {
    bottom: 3.5rem;
    right: 1.5rem;
  }
  .hed-n {
    background: var(--black);
  }

  .banner-form .select_as_btn {
    padding: 0.8rem 2.5rem !important;
    font-size: 14px;
  }

  .banner-form .select_as_btn2 {
    padding: 0.8rem 2.5rem !important;
    font-size: 14px;
  }
  .banner_btn_wrape {
    flex-direction: column;
  }
  .banner-part {
    background-size: contain;
    height: 90vh;
    margin-top: 100px;
  }
  .mbl_banner_form_wrape {
    display: block;
  }
  .mbl_banner_form_wrape .banner-form {
    box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 10%);
  }
  .desk_banner_form_wrape {
    display: none;
  }
  .banner-part .banner-content h2 {
    font-size: 36px !important;
    line-height: normal !important;
    text-align: left;
  }
  .sub-page-content h1 {
    font-size: 30px !important;
  }
  .sub-page-content ul {
    display: none !important;
  }

  h2 {
    line-height: normal !important;
    font-size: 34px !important;
    text-align: center;
  }

  .com-link svg {
    color: var(--white) !important;
  }
  .searchbar-icon .search-content .button-search svg {
    color: var(--primarycolor) !important;
  }

  .shop-faq-sec .accordion-button {
    font-size: 17px !important;
  }
  .shop-faq-sec .accordion-body p {
    margin-top: 0.5rem !important;
    text-align: justify;
  }
  .intro_common_sec p,
  .intro_common_sec ul {
    text-align: justify;
    font-size: 15px !important;
  }
  .intro_common_sec h3 {
    font-size: 22px !important;
  }
  .comon-footer .subscriber-wraper {
    /* flex-direction: column; */
  }
  .comon-footer .subscriber-wraper form {
    width: 100%;
  }
  .comon-footer .subscriber-wraper .form-control {
    /* margin-bottom: 1rem; */
  }
  .contact-map-sec .contact_bottom h6 {
    text-align: center;
  }
  .contact-map-sec .contact_details h2 {
    text-align: left;
  }
  .download_app_sec p {
    text-align: center;
  }
  .download_app_icons {
    justify-content: center !important;
  }
  .about-sec-1 h6,
  .about-offer-section h6,
  .our-staff h6,
  .about-poroduction-section h6 {
    text-align: center;
  }

  .comon-about {
    margin-bottom: 2rem;
  }
  .menu-dl-right h2 {
    text-align: left;
    margin-top: 1rem;
  }
  .bottom-details .nav-tabs .nav-link {
    font-size: 18px;
  }
  .join-club h2 {
    font-size: 34px !important;
  }
  .join-club p {
    font-size: 16px;
  }
  .join-club h3 {
    text-align: left;
    padding-bottom: 0.5rem;
  }
  .cm-text-n h6,
  .contact-form-div h6 {
    text-align: center;
  }
  .track_order_modal
    .track_order_pop_up_wrape
    .track_order_content
    .order_tracking
    .steps {
    flex-direction: column;
  }
  .rc-slider {
    width: 70% !important;
  }

  .shop-filers-sec .brands_list_wraper .brands_list li {
    font-size: 16px;
  }
  .track_order_modal
    .track_order_pop_up_wrape
    .track_order_content
    .order_tracking
    .steps
    .step {
    margin-bottom: 1.5rem;
  }
  .track_order_modal
    .track_order_pop_up_wrape
    .track_order_content
    .order_tracking
    .steps
    .step
    .step-icon-wrap::before,
  .track_order_modal
    .track_order_pop_up_wrape
    .track_order_content
    .order_tracking
    .steps
    .step
    .step-icon-wrap::after {
    display: none;
  }
  .track_order_modal .track_order_pop_up_wrape .track_order_content h2,
  .change_pass_modal .change_pass_pop_up_wrape .change_pass_content h2,
  .profile-sec h2,
  .order_wrape h2,
  .dashboard-sec h2,
  .address-sec h2,
  .add_address_modal .add_addres_pop_up_wrape .add_address_content h2 {
    font-size: 34px !important;
  }
  .empty_cart h2 {
    font-size: 25px !important;
  }
  .order_wrape .order_item_wrape .order_item {
    padding: 1.5rem;
  }

  .order_wrape .order_item_wrape .order_item .img_wrape {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .order_wrape .order_item_wrape .order_item .order_detail {
    flex-direction: column;
    align-items: flex-start;
  }
  .order_wrape .order_item_wrape .order_item .order_detail .order_number {
    padding: 0;
    border-right: 0;
  }
}

@media screen and (max-width: 1080px) {
  .comon-recent a:first-child {
    height: 60px;
    width: 76px;
  }
  .comon-recent a h5 {
    font-size: 18px;
  }
  .cm-text-n h2,
  .contact-form-div h2 {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 667px) {
  .DeskTOpVIew {
    display: none !important;
  }

  .mobileVIew {
    display: block !important;
  }
  .logo-main {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
  /* .login-top {
    display: none !important;
  } */
  /* body */
  .cate-list li a {
    font-size: 16px;
  }
  .comon-recent a h5 {
    font-size: 20px;
  }
  .banner-part .banner-content {
    position: absolute;
    top: 7%;
    width: 80%;
  }
  .banner-part .banner-content h2 {
    font-size: 20px !important;
  }

  .banner-part .banner-content h2 span {
    font-size: 50px !important;
  }
  .banner-form .form-control {
    font-size: 10px;
  }
}

a:not([href]):not([class]):hover {
  color: rgb(130, 130, 130);
}

@media screen and (max-width: 480px) {
  .miblSlickederarrow .slick-slider {
    padding-top: 30px;
  }
  .miblSlickederarrow .prevArrow,
  .miblSlickederarrow .nextArrow {
    color: white;
    position: absolute;
    top: -30px;
    padding: 8px;
    background-color: var(--primarycolor);
  }
  .miblSlickederarrow .prevArrow {
    left: 35px;
  }
  .miblSlickederarrow .nextArrow {
    right: 35px;
  }
  .FIlterBnts {
    display: block;
    margin: 20px auto;
    background-color: var(--primarycolor);
    color: white;
    outline: none;
    border: none;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SHop_Resp {
    display: block;
  }
  .DeskTopFIlter {
    display: none;
  }
  .DeskImgBanner {
    display: none;
  }
  .mobileImgBanner {
    display: block;
  }
  .video_background {
    max-height: 840px;
  }
  .banner-part .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 90%;
  }

  .banner-part .banner-content h2 {
    font-size: 14px !important;
  }

  .banner-part .banner-content h2 span {
    font-size: 40px !important;
  }
  .top_app_sec .top_app_icons img {
    width: 50px;
  }
  .top_app_sec .curveStyle {
    font-size: 11px;
    font-weight: 600;
  }
  .filter-menu-q .comon-filter-bn {
    margin: 0 4px;
  }

  .cart-btn_main {
    font-size: 14px !important;
    padding: 0.4rem 1rem !important;
  }
  /* comon header */
  .logo-main {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
  /* .login-top {
    display: none !important;
  } */
  .slider-content {
    width: 100%;
    padding: 0;
  }
  .slider h2 {
    font-size: 44px !important;
  }
  h2 {
    font-weight: 41px !important;
  }
  /* comon header ends */
  /* banner */
  .subpage-banner {
    /* height: 270px; */
    height: 280px;
    margin-top: 110px;
    background-size: 100% 100% !important;
  }
  /* body */
  .comon-offer h5 {
    text-align: left;
    padding-left: 15px;
  }
  .comon-offer h5 span {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .comon-offer h5 {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  .comon-offer h5 div {
    gap: 10px;
  }
  .OfferStoreLogo {
    width: 100px;
  }
  .DeliveryText {
    display: none;
  }
  .height-offer {
    height: 200px;
  }
  .tesimonial-sec-div figure img {
    opacity: 0.8;
  }
  .bottom-details .nav-tabs .nav-link {
    font-size: 18px;
    margin-right: 6px;
    padding: 0.5rem 1rem;
  }
  .image-gallery .image-gallery-image {
    height: 52vh !important;
  }
}
